// @flow
import React from 'react';
import {withStyles} from '@material-ui/core/styles';
import {isWidthDown} from '@material-ui/core/withWidth';
import type {DefaultComponentProps} from '../../../../types/Types';
import Image from '../../helper/Image';
import {Typography} from "@material-ui/core";
import {withLocalization} from "react-localize";
import SwipeableViews from 'react-swipeable-views';
import classNames from 'classnames';

import ratingLGWebP2x from '../../../../images/dest/home/rating_web-lg@2x.webp';
import ratingLGWebP from '../../../../images/dest/home/rating_web-lg@1x.webp';
import ratingMDWebP2x from '../../../../images/dest/home/rating_web-md@2x.webp';
import ratingMDWebP from '../../../../images/dest/home/rating_web-md@1x.webp';
import ratingSMWebP2x from '../../../../images/dest/home/rating_web-sm@2x.webp';
import ratingSMWebP from '../../../../images/dest/home/rating_web-sm@1x.webp';
import ratingXSWebP2x from '../../../../images/dest/home/rating_web-xs@2x.webp';
import ratingXSWebP from '../../../../images/dest/home/rating_web-xs@1x.webp';
import ratingLGJPG2x from '../../../../images/dest/home/rating_web-lg@2x.jpg';
import ratingLGJPG from '../../../../images/dest/home/rating_web-lg@1x.jpg';
import ratingMDJPG2x from '../../../../images/dest/home/rating_web-md@2x.jpg';
import ratingMDJPG from '../../../../images/dest/home/rating_web-md@1x.jpg';
import ratingSMJPG2x from '../../../../images/dest/home/rating_web-sm@2x.jpg';
import ratingSMJPG from '../../../../images/dest/home/rating_web-sm@1x.jpg';
import ratingXSJPG2x from '../../../../images/dest/home/rating_web-xs@2x.jpg';
import ratingXSJPG from '../../../../images/dest/home/rating_web-xs@1x.jpg';

import workLGWebP2x from '../../../../images/dest/home/work_web-lg@2x.webp';
import workLGWebP from '../../../../images/dest/home/work_web-lg@1x.webp';
import workMDWebP2x from '../../../../images/dest/home/work_web-md@2x.webp';
import workMDWebP from '../../../../images/dest/home/work_web-md@1x.webp';
import workSMWebP2x from '../../../../images/dest/home/work_web-sm@2x.webp';
import workSMWebP from '../../../../images/dest/home/work_web-sm@1x.webp';
import workXSWebP2x from '../../../../images/dest/home/work_web-xs@2x.webp';
import workXSWebP from '../../../../images/dest/home/work_web-xs@1x.webp';
import workLGJPG2x from '../../../../images/dest/home/work_web-lg@2x.jpg';
import workLGJPG from '../../../../images/dest/home/work_web-lg@1x.jpg';
import workMDJPG2x from '../../../../images/dest/home/work_web-md@2x.jpg';
import workMDJPG from '../../../../images/dest/home/work_web-md@1x.jpg';
import workSMJPG2x from '../../../../images/dest/home/work_web-sm@2x.jpg';
import workSMJPG from '../../../../images/dest/home/work_web-sm@1x.jpg';
import workXSJPG2x from '../../../../images/dest/home/work_web-xs@2x.jpg';
import workXSJPG from '../../../../images/dest/home/work_web-xs@1x.jpg';

import connectingLGWebP2x from '../../../../images/dest/home/connecting_web-lg@2x.webp';
import connectingLGWebP from '../../../../images/dest/home/connecting_web-lg@1x.webp';
import connectingMDWebP2x from '../../../../images/dest/home/connecting_web-md@2x.webp';
import connectingMDWebP from '../../../../images/dest/home/connecting_web-md@1x.webp';
import connectingSMWebP2x from '../../../../images/dest/home/connecting_web-sm@2x.webp';
import connectingSMWebP from '../../../../images/dest/home/connecting_web-sm@1x.webp';
import connectingXSWebP2x from '../../../../images/dest/home/connecting_web-xs@2x.webp';
import connectingXSWebP from '../../../../images/dest/home/connecting_web-xs@1x.webp';
import connectingLGJPG2x from '../../../../images/dest/home/connecting_web-lg@2x.jpg';
import connectingLGJPG from '../../../../images/dest/home/connecting_web-lg@1x.jpg';
import connectingMDJPG2x from '../../../../images/dest/home/connecting_web-md@2x.jpg';
import connectingMDJPG from '../../../../images/dest/home/connecting_web-md@1x.jpg';
import connectingSMJPG2x from '../../../../images/dest/home/connecting_web-sm@2x.jpg';
import connectingSMJPG from '../../../../images/dest/home/connecting_web-sm@1x.jpg';
import connectingXSJPG2x from '../../../../images/dest/home/connecting_web-xs@2x.jpg';
import connectingXSJPG from '../../../../images/dest/home/connecting_web-xs@1x.jpg';

import inspireLGWebP2x from '../../../../images/dest/home/inspire_web-lg@2x.webp';
import inspireLGWebP from '../../../../images/dest/home/inspire_web-lg@1x.webp';
import inspireMDWebP2x from '../../../../images/dest/home/inspire_web-md@2x.webp';
import inspireMDWebP from '../../../../images/dest/home/inspire_web-md@1x.webp';
import inspireSMWebP2x from '../../../../images/dest/home/inspire_web-sm@2x.webp';
import inspireSMWebP from '../../../../images/dest/home/inspire_web-sm@1x.webp';
import inspireXSWebP2x from '../../../../images/dest/home/inspire_web-xs@2x.webp';
import inspireXSWebP from '../../../../images/dest/home/inspire_web-xs@1x.webp';
import inspireLGJPG2x from '../../../../images/dest/home/inspire_web-lg@2x.jpg';
import inspireLGJPG from '../../../../images/dest/home/inspire_web-lg@1x.jpg';
import inspireMDJPG2x from '../../../../images/dest/home/inspire_web-md@2x.jpg';
import inspireMDJPG from '../../../../images/dest/home/inspire_web-md@1x.jpg';
import inspireSMJPG2x from '../../../../images/dest/home/inspire_web-sm@2x.jpg';
import inspireSMJPG from '../../../../images/dest/home/inspire_web-sm@1x.jpg';
import inspireXSJPG2x from '../../../../images/dest/home/inspire_web-xs@2x.jpg';
import inspireXSJPG from '../../../../images/dest/home/inspire_web-xs@1x.jpg';

import assistLGWebP2x from '../../../../images/dest/home/assist_web-lg@2x.webp';
import assistLGWebP from '../../../../images/dest/home/assist_web-lg@1x.webp';
import assistMDWebP2x from '../../../../images/dest/home/assist_web-md@2x.webp';
import assistMDWebP from '../../../../images/dest/home/assist_web-md@1x.webp';
import assistSMWebP2x from '../../../../images/dest/home/assist_web-sm@2x.webp';
import assistSMWebP from '../../../../images/dest/home/assist_web-sm@1x.webp';
import assistXSWebP2x from '../../../../images/dest/home/assist_web-xs@2x.webp';
import assistXSWebP from '../../../../images/dest/home/assist_web-xs@1x.webp';
import assistLGJPG2x from '../../../../images/dest/home/assist_web-lg@2x.jpg';
import assistLGJPG from '../../../../images/dest/home/assist_web-lg@1x.jpg';
import assistMDJPG2x from '../../../../images/dest/home/assist_web-md@2x.jpg';
import assistMDJPG from '../../../../images/dest/home/assist_web-md@1x.jpg';
import assistSMJPG2x from '../../../../images/dest/home/assist_web-sm@2x.jpg';
import assistSMJPG from '../../../../images/dest/home/assist_web-sm@1x.jpg';
import assistXSJPG2x from '../../../../images/dest/home/assist_web-xs@2x.jpg';
import assistXSJPG from '../../../../images/dest/home/assist_web-xs@1x.jpg';

type SliderProps = DefaultComponentProps & {
    headline: string,
    date: string,
    content: string
};

const style: Function = (theme: Object): Object => ({
    root: {
        position: 'relative',
        marginBottom: 85,
        [theme.breakpoints.down('sm')]: {
            marginBottom: 0
        }
    },
    sliderItemSurround: {
        padding: 15,
        position: 'relative',
        [theme.breakpoints.down('sm')]: {
            padding: 5
        }
    },
    image: {
        width: '100%'
    },
    step: theme.step,
    headline: {
        textAlign: 'center',
        [theme.breakpoints.down('sm')]: {
            width: '90%',
            margin: '0 auto'
        }
    },
    headline2: {
        marginBottom: 112,
        [theme.breakpoints.down('sm')]: {
            marginBottom: 30
        }
    },
    sliderItemContentSurround: {
        position: 'absolute',
        background: 'white',
        boxSizing: 'border-box',
        width: 500,
        bottom: 48,
        left: 66,
        padding: '40px 45px 40px 45px',
        [theme.breakpoints.down('sm')]: {
            position: 'relative',
            top: 0,
            left: 0,
            bottom: 0,
            padding: 0,
            width: '80%',
            textAlign: 'center',
            margin: '0 auto'
        }
    },
    sliderItemContentHeadline: {
        fontFamily: 'Playfair Display',
        marginBottom: 21,
        color: '#171717',
        fontWeight: 400,
        [theme.breakpoints.down('sm')]: {
            marginTop: 20,
            marginBottom: 16
        }
    },
    sliderItemContentText: {
        marginBottom: 30
    },
    caption: {
        display: 'flex',
        alignItems: 'center',
        [theme.breakpoints.down('sm')]: {
            display: 'none'
        }
    },
    captionDivider: {
        height: 2.5,
        background: '#7C7C7C',
        width: 24,
        display: 'block',
        marginLeft: 11
    },
    selectBlock: {
        position: 'absolute',
        top: 0,
        left: 0,
        bottom: 0,
        right: 0,
        zIndex: 4
    }
});

class Slider extends React.Component<SliderProps> {


    render() {
        return (
            <div className={this.props.classes.root}>
                <div className={this.props.classes.step}>
                    04
                </div>
                <Typography variant={'h1'} classes={{
                    root: this.props.classes.headline
                }}>
                    {this.props.localize('home.slider.headline.1')}
                </Typography>
                <Typography variant={'h1'} classes={{
                    root: classNames(this.props.classes.headline, this.props.classes.headline2)
                }}>
                    {this.props.localize('home.slider.headline.2')}
                </Typography>

                <SwipeableViews style={{
                    padding: `0 ${isWidthDown('sm', this.props.width) ? 20 : 124}px`
                }} enableMouseEvents={true} resistance={true}>
                    <div className={this.props.classes.sliderItemSurround}>
                        <div className={this.props.classes.selectBlock} />
                        <Image srcSet={{
                            webP: {
                                lg2x: ratingLGWebP2x,
                                lg: ratingLGWebP,
                                md2x: ratingMDWebP2x,
                                md: ratingMDWebP,
                                sm2x: ratingSMWebP2x,
                                sm: ratingSMWebP,
                                xs2x: ratingXSWebP2x,
                                xs: ratingXSWebP
                            },
                            fallback: {
                                lg2x: ratingLGJPG2x,
                                lg: ratingLGJPG,
                                md2x: ratingMDJPG2x,
                                md: ratingMDJPG,
                                sm2x: ratingSMJPG2x,
                                sm: ratingSMJPG,
                                xs2x: ratingXSJPG2x,
                                xs: ratingXSJPG
                            }
                        }} alt={''} className={this.props.classes.image}/>
                        <div className={this.props.classes.sliderItemContentSurround}>
                            <Typography variant={'h2'} classes={{
                                root: this.props.classes.sliderItemContentHeadline
                            }}>
                                {this.props.localize('home.slider.item.1.headline')}
                            </Typography>
                            <Typography variant={'body1'} classes={{
                                root: this.props.classes.sliderItemContentText
                            }}>
                                {this.props.localize('home.slider.item.1.text')}
                            </Typography>
                        </div>
                    </div>
                    <div className={this.props.classes.sliderItemSurround}>
                        <div className={this.props.classes.selectBlock} />
                        <Image forceLoad={true} srcSet={{
                            webP: {
                                lg2x: workLGWebP2x,
                                lg: workLGWebP,
                                md2x: workMDWebP2x,
                                md: workMDWebP,
                                sm2x: workSMWebP2x,
                                sm: workSMWebP,
                                xs2x: workXSWebP2x,
                                xs: workXSWebP
                            },
                            fallback: {
                                lg2x: workLGJPG2x,
                                lg: workLGJPG,
                                md2x: workMDJPG2x,
                                md: workMDJPG,
                                sm2x: workSMJPG2x,
                                sm: workSMJPG,
                                xs2x: workXSJPG2x,
                                xs: workXSJPG
                            }
                        }} alt={''} className={this.props.classes.image}/>
                        <div className={this.props.classes.sliderItemContentSurround}>
                            <Typography variant={'h2'} classes={{
                                root: this.props.classes.sliderItemContentHeadline
                            }}>
                                {this.props.localize('home.slider.item.2.headline')}
                            </Typography>
                            <Typography variant={'body1'} classes={{
                                root: this.props.classes.sliderItemContentText
                            }}>
                                {this.props.localize('home.slider.item.2.text')}
                            </Typography>
                        </div>
                    </div>
                    <div className={this.props.classes.sliderItemSurround}>
                        <div className={this.props.classes.selectBlock} />
                        <Image forceLoad={true} srcSet={{
                            webP: {
                                lg2x: connectingLGWebP2x,
                                lg: connectingLGWebP,
                                md2x: connectingMDWebP2x,
                                md: connectingMDWebP,
                                sm2x: connectingSMWebP2x,
                                sm: connectingSMWebP,
                                xs2x: connectingXSWebP2x,
                                xs: connectingXSWebP
                            },
                            fallback: {
                                lg2x: connectingLGJPG2x,
                                lg: connectingLGJPG,
                                md2x: connectingMDJPG2x,
                                md: connectingMDJPG,
                                sm2x: connectingSMJPG2x,
                                sm: connectingSMJPG,
                                xs2x: connectingXSJPG2x,
                                xs: connectingXSJPG
                            }
                        }} alt={''} className={this.props.classes.image}/>
                        <div className={this.props.classes.sliderItemContentSurround}>
                            <Typography variant={'h2'} classes={{
                                root: this.props.classes.sliderItemContentHeadline
                            }}>
                                {this.props.localize('home.slider.item.3.headline')}
                            </Typography>
                            <Typography variant={'body1'} classes={{
                                root: this.props.classes.sliderItemContentText
                            }}>
                                {this.props.localize('home.slider.item.3.text')}
                            </Typography>
                        </div>
                    </div>
                    <div className={this.props.classes.sliderItemSurround}>
                        <div className={this.props.classes.selectBlock} />
                        <Image forceLoad={true} srcSet={{
                            webP: {
                                lg2x: inspireLGWebP2x,
                                lg: inspireLGWebP,
                                md2x: inspireMDWebP2x,
                                md: inspireMDWebP,
                                sm2x: inspireSMWebP2x,
                                sm: inspireSMWebP,
                                xs2x: inspireXSWebP2x,
                                xs: inspireXSWebP
                            },
                            fallback: {
                                lg2x: inspireLGJPG2x,
                                lg: inspireLGJPG,
                                md2x: inspireMDJPG2x,
                                md: inspireMDJPG,
                                sm2x: inspireSMJPG2x,
                                sm: inspireSMJPG,
                                xs2x: inspireXSJPG2x,
                                xs: inspireXSJPG
                            }
                        }} alt={''} className={this.props.classes.image}/>
                        <div className={this.props.classes.sliderItemContentSurround}>
                            <Typography variant={'h2'} classes={{
                                root: this.props.classes.sliderItemContentHeadline
                            }}>
                                {this.props.localize('home.slider.item.4.headline')}
                            </Typography>
                            <Typography variant={'body1'} classes={{
                                root: this.props.classes.sliderItemContentText
                            }}>
                                {this.props.localize('home.slider.item.4.text')}
                            </Typography>
                        </div>
                    </div>
                    <div className={this.props.classes.sliderItemSurround}>
                        <div className={this.props.classes.selectBlock} />
                        <Image forceLoad={true} srcSet={{
                            webP: {
                                lg2x: assistLGWebP2x,
                                lg: assistLGWebP,
                                md2x: assistMDWebP2x,
                                md: assistMDWebP,
                                sm2x: assistSMWebP2x,
                                sm: assistSMWebP,
                                xs2x: assistXSWebP2x,
                                xs: assistXSWebP
                            },
                            fallback: {
                                lg2x: assistLGJPG2x,
                                lg: assistLGJPG,
                                md2x: assistMDJPG2x,
                                md: assistMDJPG,
                                sm2x: assistSMJPG2x,
                                sm: assistSMJPG,
                                xs2x: assistXSJPG2x,
                                xs: assistXSJPG
                            }
                        }} alt={''} className={this.props.classes.image}/>
                        <div className={this.props.classes.sliderItemContentSurround}>
                            <Typography variant={'h2'} classes={{
                                root: this.props.classes.sliderItemContentHeadline
                            }}>
                                {this.props.localize('home.slider.item.5.headline')}
                            </Typography>
                            <Typography variant={'body1'} classes={{
                                root: this.props.classes.sliderItemContentText
                            }}>
                                {this.props.localize('home.slider.item.5.text')}
                            </Typography>
                        </div>
                    </div>
                </SwipeableViews>
            </div>
        );
    }
}

export default withStyles(style)(withLocalization(Slider));
