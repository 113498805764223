// @flow
import fetch from 'isomorphic-fetch';
import {checkServerResponse, requestConfig, RequestMethod} from './RequestHelper';

export const Route = {
    ESTATES: '/estates/',
    REFERENCES: '/estates/references',
    CONTACT: '/contact/mail/send'
};

export const fetchArray: Function = async (url: String): Promise<Array<Object>> =>
    checkServerResponse(await fetch(url, requestConfig(RequestMethod.GET)));

export const sendContactMessage: Function = async (url: string, contactData: Object): Promise<typeof undefined | Object> =>
    checkServerResponse(await fetch(url, requestConfig(RequestMethod.POST, contactData)));
