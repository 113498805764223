// @flow
import {connect} from 'react-redux';
import App from '../presentational/App';
import type {AppState} from '../../types/Types';
import {createAction} from "redux-actions";
import ActionTypes from "../../actions/ActionTypes";
import FetchStatus from '../../api/FetchStatus';

/***************************************************************************
 * The base redux container which will be mounted.
 ****************************************************************************/


const mapStateToProps = (state: AppState): Object => ({
    language: state.application.language.selected,
    menuOpen: state.application.menu ? state.application.menu.open : false,
    contactFetchStatus: state.application.contact ? state.application.contact.fetchStatus : FetchStatus.SUCCESS
});

const mapDispatchToProps = (dispatch: Function): Object => ({
    toggleMenu: () => dispatch(createAction(ActionTypes.TOGGLE_MENU)()),
    loadEstates: () => dispatch(createAction(ActionTypes.LOAD_ESTATES)())
});


const WebApp: Object = connect(mapStateToProps, mapDispatchToProps)(App);

export default WebApp;
