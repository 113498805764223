// @flow
import React from 'react';
import {withLocalization} from 'react-localize';
import {withStyles} from '@material-ui/core/styles';
import withWidth, {isWidthDown} from '@material-ui/core/withWidth';
import {Link} from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import {DESKTOP_CONTENT_MAX_WIDTH} from '../../../../constants/constants';
import Image from '../../helper/Image';
import classNames from 'classnames';
import type {DefaultComponentProps} from '../../../../types/Types';

import housesLGJPG from '../../../../images/dest/home/unsere_haeuser_web-lg@1x.jpg';
import housesLGJPG2x from '../../../../images/dest/home/unsere_haeuser_web-lg@2x.jpg';
import housesLGWebP from '../../../../images/dest/home/unsere_haeuser_web-lg@1x.webp';
import housesLGWebP2x from '../../../../images/dest/home/unsere_haeuser_web-lg@2x.webp';
import housesMDJPG from '../../../../images/dest/home/unsere_haeuser_web-md@1x.jpg';
import housesMDJPG2x from '../../../../images/dest/home/unsere_haeuser_web-md@2x.jpg';
import housesMDWebP from '../../../../images/dest/home/unsere_haeuser_web-md@1x.webp';
import housesMDWebP2x from '../../../../images/dest/home/unsere_haeuser_web-md@2x.webp';
import housesSMJPG from '../../../../images/dest/home/unsere_haeuser_web-sm@1x.jpg';
import housesSMJPG2x from '../../../../images/dest/home/unsere_haeuser_web-sm@2x.jpg';
import housesSMWebP from '../../../../images/dest/home/unsere_haeuser_web-sm@1x.webp';
import housesSMWebP2x from '../../../../images/dest/home/unsere_haeuser_web-sm@2x.webp';
import housesXSJPG from '../../../../images/dest/home/unsere_haeuser_web-xs@1x.jpg';
import housesXSJPG2x from '../../../../images/dest/home/unsere_haeuser_web-xs@2x.jpg';
import housesXSWebP from '../../../../images/dest/home/unsere_haeuser_web-xs@1x.webp';
import housesXSWebP2x from '../../../../images/dest/home/unsere_haeuser_web-xs@2x.webp';

import housesIntroLGJPG from '../../../../images/dest/home/haeuser_intro_web-lg@1x.jpg';
import housesIntroLGJPG2x from '../../../../images/dest/home/haeuser_intro_web-lg@2x.jpg';
import housesIntroLGWebP from '../../../../images/dest/home/haeuser_intro_web-lg@1x.webp';
import housesIntroLGWebP2x from '../../../../images/dest/home/haeuser_intro_web-lg@2x.webp';
import housesIntroMDJPG from '../../../../images/dest/home/haeuser_intro_web-md@1x.jpg';
import housesIntroMDJPG2x from '../../../../images/dest/home/haeuser_intro_web-md@2x.jpg';
import housesIntroMDWebP from '../../../../images/dest/home/haeuser_intro_web-md@1x.webp';
import housesIntroMDWebP2x from '../../../../images/dest/home/haeuser_intro_web-md@2x.webp';
import housesIntroSMJPG from '../../../../images/dest/home/haeuser_intro_web-sm@1x.jpg';
import housesIntroSMJPG2x from '../../../../images/dest/home/haeuser_intro_web-sm@2x.jpg';
import housesIntroSMWebP from '../../../../images/dest/home/haeuser_intro_web-sm@1x.webp';
import housesIntroSMWebP2x from '../../../../images/dest/home/haeuser_intro_web-sm@2x.webp';
import housesIntroXSJPG from '../../../../images/dest/home/haeuser_intro_web-xs@1x.jpg';
import housesIntroXSJPG2x from '../../../../images/dest/home/haeuser_intro_web-xs@2x.jpg';
import housesIntroXSWebP from '../../../../images/dest/home/haeuser_intro_web-xs@1x.webp';
import housesIntroXSWebP2x from '../../../../images/dest/home/haeuser_intro_web-xs@2x.webp';

import apartmentsIntroLGJPG from '../../../../images/dest/home/wohnungen_intro_web-lg@1x.jpg';
import apartmentsIntroLGJPG2x from '../../../../images/dest/home/wohnungen_intro_web-lg@2x.jpg';
import apartmentsIntroLGWebP from '../../../../images/dest/home/wohnungen_intro_web-lg@1x.webp';
import apartmentsIntroLGWebP2x from '../../../../images/dest/home/wohnungen_intro_web-lg@2x.webp';
import apartmentsIntroMDJPG from '../../../../images/dest/home/wohnungen_intro_web-md@1x.jpg';
import apartmentsIntroMDJPG2x from '../../../../images/dest/home/wohnungen_intro_web-md@2x.jpg';
import apartmentsIntroMDWebP from '../../../../images/dest/home/wohnungen_intro_web-md@1x.webp';
import apartmentsIntroMDWebP2x from '../../../../images/dest/home/wohnungen_intro_web-md@2x.webp';
import apartmentsIntroSMJPG from '../../../../images/dest/home/wohnungen_intro_web-sm@1x.jpg';
import apartmentsIntroSMJPG2x from '../../../../images/dest/home/wohnungen_intro_web-sm@2x.jpg';
import apartmentsIntroSMWebP from '../../../../images/dest/home/wohnungen_intro_web-sm@1x.webp';
import apartmentsIntroSMWebP2x from '../../../../images/dest/home/wohnungen_intro_web-sm@2x.webp';
import apartmentsIntroXSJPG from '../../../../images/dest/home/wohnungen_intro_web-xs@1x.jpg';
import apartmentsIntroXSJPG2x from '../../../../images/dest/home/wohnungen_intro_web-xs@2x.jpg';
import apartmentsIntroXSWebP from '../../../../images/dest/home/wohnungen_intro_web-xs@1x.webp';
import apartmentsIntroXSWebP2x from '../../../../images/dest/home/wohnungen_intro_web-xs@2x.webp';

import referencesIntroLGJPG from '../../../../images/dest/home/referenzen_intro_web-lg@1x.jpg';
import referencesIntroLGJPG2x from '../../../../images/dest/home/referenzen_intro_web-lg@2x.jpg';
import referencesIntroLGWebP from '../../../../images/dest/home/referenzen_intro_web-lg@1x.webp';
import referencesIntroLGWebP2x from '../../../../images/dest/home/referenzen_intro_web-lg@2x.webp';
import referencesIntroMDJPG from '../../../../images/dest/home/referenzen_intro_web-md@1x.jpg';
import referencesIntroMDJPG2x from '../../../../images/dest/home/referenzen_intro_web-md@2x.jpg';
import referencesIntroMDWebP from '../../../../images/dest/home/referenzen_intro_web-md@1x.webp';
import referencesIntroMDWebP2x from '../../../../images/dest/home/referenzen_intro_web-md@2x.webp';
import referencesIntroSMJPG from '../../../../images/dest/home/referenzen_intro_web-sm@1x.jpg';
import referencesIntroSMJPG2x from '../../../../images/dest/home/referenzen_intro_web-sm@2x.jpg';
import referencesIntroSMWebP from '../../../../images/dest/home/referenzen_intro_web-sm@1x.webp';
import referencesIntroSMWebP2x from '../../../../images/dest/home/referenzen_intro_web-sm@2x.webp';
import referencesIntroXSJPG from '../../../../images/dest/home/referenzen_intro_web-xs@1x.jpg';
import referencesIntroXSJPG2x from '../../../../images/dest/home/referenzen_intro_web-xs@2x.jpg';
import referencesIntroXSWebP from '../../../../images/dest/home/referenzen_intro_web-xs@1x.webp';
import referencesIntroXSWebP2x from '../../../../images/dest/home/referenzen_intro_web-xs@2x.webp';

type ReferencesProps = DefaultComponentProps & {
    hasReferences: boolean,
    hasApartments: boolean,
    hasHouses: boolean
};

type ReferencesState = {
    width: number
};

const style: Function = (theme: Object): Object => ({
    root: {
        marginTop: 300,
        position: 'relative',
        marginBottom: 300,
        [theme.breakpoints.down('sm')]: {
            marginTop: 77,
            marginBottom: 80
        }
    },
    section: {
        position: 'relative'
    },
    housesImage: {
        left: 0,
        top: 200,
        position: 'absolute',
        zIndex: 1
    },
    houseIntroImageSurround: {
        ...theme.link,
        position: 'absolute',
        top: 0,
        right: 0,
        zIndex: 2,
        opacity: 1,
        display: 'block',
        [theme.breakpoints.down('sm')]: {
            position: 'relative',
            top: 0,
            left: 0,
            padding: 0,
            width: '100%'
        }
    },
    houseIntroImage: {
        width: '100%',
        boxShadow: 'inset 0 1px 3px 0 rgba(0,0,0,0.5), 30px 60px 80px 0 rgba(0,0,0,0.15)'
    },
    step: theme.step,
    section2: {
        width: DESKTOP_CONTENT_MAX_WIDTH,
        margin: '0 auto',
        display: 'flex',
        justifyContent: 'flex-end',
        [theme.breakpoints.down('lg')]: {
            width: '90%'
        },
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            flexWrap: 'wrap'
        }
    },
    apartmentIntroImageSurround: {
        ...theme.link,
        position: 'absolute',
        top: -183,
        left: 0,
        width: 785,
        zIndex: 3,
        display: 'block',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            position: 'relative',
            top: 0,
            left: 0,
            marginTop: 20
        }
    },
    apartmentIntroImage: {
        boxShadow: 'inset 0 1px 3px 0 rgba(0, 0, 0, 0.5), 0 140px 80px -20px rgba(0, 0, 0, 0.1)',
        width: '100%',
        [theme.breakpoints.down('sm')]: {
            width: '55%'
        }
    },
    referencesSurround: {
        ...theme.link,
        width: 400,
        marginTop: 60,
        marginRight: 90,
        display: 'block',
        [theme.breakpoints.down('lg')]: {
            width: '30%',
            marginRight: 10
        },
        [theme.breakpoints.down('sm')]: {
            width: '60%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-end',
            marginTop: 15,
        }
    },
    headlineSurround: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-end',
        width: 270,
        marginBottom: 22
    },
    headline: {
        fontFamily: 'Playfair Display',
        fontWeight: 400,
        letterSpacing: '1.58px',
        [theme.breakpoints.down('sm')]: {
            marginBottom: 0
        }
    },
    caption: {
        display: 'flex',
        alignItems: 'center',
        fontSize: 20,
        fontWeight: 400,
        textTransform: 'none',
        [theme.breakpoints.down('sm')]: {
            color: '#808080'
        }
    },
    captionText: {
        order: 1
    },
    captionDivider: {
        height: 2.5,
        background: 'black',
        width: 24,
        display: 'block',
        marginLeft: 11,
        order: 2,
        [theme.breakpoints.down('sm')]: {
            background: '#808080'
        }
    },
    referencesIntroImage: {
        marginLeft: 11,
        boxShadow: 'inset 0 1px 3px 0 rgba(0,0,0,0.5), 0 140px 80px -20px rgba(0,0,0,0.05)',
        width: '100%'
    },
    referencesIntroPicture: {
        [theme.breakpoints.down('sm')]: {
            width: '55%',
            marginRight: '2%'
        }
    },
    houseIntroHeadlineSurround: {
        position: 'absolute',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-end',
        top: 57,
        right: 83,
        marginBottom: 20,
        [theme.breakpoints.down('sm')]: {
            position: 'relative',
            top: 0,
            left: 0,
            padding: 0,
            width: '80%',
            margin: '0 auto',
            alignItems: 'flex-start',
            marginBottom: 10
        }
    },
    houseIntroHeadline: {
        color: 'white',
        [theme.breakpoints.down('sm')]: {
            color: '#171717'
        }
    },
    houseIntroCaption: {
        color: 'white',
        [theme.breakpoints.down('sm')]: {
            color: '#808080'
        }
    },
    houseIntroCaptionDivider: {
        background: 'white',
        [theme.breakpoints.down('sm')]: {
            background: '#808080',
            order: 0,
            marginLeft: 0,
            marginRight: 11
        }
    },
    apartmentIntroHeadlineSurround: {
        position: 'absolute',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-end',
        bottom: 35,
        left: 110,
        [theme.breakpoints.down('sm')]: {
            position: 'relative',
            top: 0,
            left: 0,
            marginTop: 20,
            alignItems: 'flex-start',
            marginLeft: '5%'
        }
    },
    referencesHeadlineSurround: {
        [theme.breakpoints.down('sm')]: {
            alignItems: 'flex-end',
            width: 'auto',
        }
    },
    contentSurround: {
        width: 525,
        position: 'absolute',
        top: 304,
        zIndex: 5,
        background: 'white',
        padding: '62px 67px 62px 91px',
        boxSizing: 'border-box',
        [theme.breakpoints.down('sm')]: {
            position: 'relative',
            top: 0,
            left: 0,
            padding: 0,
            width: '90%',
            margin: '0 auto',
            textAlign: 'center',
            marginBottom: 20
        }
    },
    contentHeadline: {
        fontFamily: 'Playfair Display',
        color: '#171717',
        letterSpacing: '1.56px',
        fontWeight: 400,
        marginBottom: 25
    },
    link: theme.link,
    button: {
        ...theme.button,
        marginTop: 20
    }
});

class References extends React.Component<ReferencesProps, ReferencesState> {

    constructor(props: ReferencesProps) {
        super(props);
        this.state = {
            width: window.innerWidth
        };
        window.addEventListener('resize', this.updateWidth.bind(this));
    }

    updateWidth() {
        this.setState({
            width: window.innerWidth
        });
    }

    getHousesImageDimensions() {
        let width;
        if (this.state.width > DESKTOP_CONTENT_MAX_WIDTH) {
            width = this.state.width - (this.state.width - DESKTOP_CONTENT_MAX_WIDTH) / 2;
        } else {
            width = this.state.width * 0.95;
        }
        return {
            width,
            height: width * 0.622
        }
    }

    getHousesIntroImageDimensions() {
        if (!isWidthDown('sm', this.props.width)) {
            let width;
            let right;
            if (this.state.width > DESKTOP_CONTENT_MAX_WIDTH) {
                width = 650;
                right = (this.state.width - DESKTOP_CONTENT_MAX_WIDTH) / 2 - 150;
            } else {
                width = 650 * this.state.width * 0.9 / DESKTOP_CONTENT_MAX_WIDTH;
                right = 20;
            }
            return {
                width,
                height: width * 0.704,
                right
            }
        }
        return {};
    }

    getContentPosition() {
        let left = 0;
        if (this.state.width > DESKTOP_CONTENT_MAX_WIDTH) {
            left = (this.state.width - DESKTOP_CONTENT_MAX_WIDTH) / 2 - 87;
        } else {
            left = 20;
        }
        return {left};
    }

    getApartmentIntroImageStyle() {
        if (!isWidthDown('sm', this.props.width)) {
            let width;
            let top;
            let left;
            if (this.state.width > DESKTOP_CONTENT_MAX_WIDTH) {
                width = 785;
                top = -183;
                left = 0;
            } else {
                width = this.state.width * 0.4;
                top = -this.state.width * 0.127;
                left = 40;
            }
            return {
                width,
                top,
                left
            }
        }
        return {};
    }

    render() {
        const housesImageDimensions = this.getHousesImageDimensions();
        return (
            <div className={this.props.classes.root}>
                <div className={this.props.classes.step}>
                    02
                </div>
                <div className={this.props.classes.section} style={{
                    height: !isWidthDown('sm', this.props.width) ? housesImageDimensions.height + 200 : 'auto'
                }}>
                    <div className={this.props.classes.contentSurround} style={this.getContentPosition()}>
                        <Typography variant={'h2'} classes={{
                            root: this.props.classes.contentHeadline
                        }}>
                            {this.props.localize('home.references.content.headline')}
                        </Typography>
                        <Typography variant={'body1'} classes={{
                            root: this.props.classes.content
                        }}>
                            {this.props.localize('home.references.content.text')}
                        </Typography>

                        {isWidthDown('sm', this.props.width) ?
                            <Button color={'primary'} className={this.props.classes.button}>
                                <Link className={this.props.classes.link} to={'/properties'}>
                                    {this.props.localize('home.properties.button')}
                                </Link>
                            </Button>
                            : null
                        }
                    </div>
                    {!isWidthDown('sm', this.props.width) ?
                        <Image srcSet={{
                            webP: {
                                lg2x: housesLGWebP2x,
                                lg: housesLGWebP,
                                md2x: housesMDWebP2x,
                                md: housesMDWebP,
                                sm2x: housesSMWebP2x,
                                sm: housesSMWebP,
                                xs2x: housesXSWebP2x,
                                xs: housesXSWebP
                            },
                            fallback: {
                                lg2x: housesLGJPG2x,
                                lg: housesLGJPG,
                                md2x: housesMDJPG2x,
                                md: housesMDJPG,
                                sm2x: housesSMJPG2x,
                                sm: housesSMJPG,
                                xs2x: housesXSJPG2x,
                                xs: housesXSJPG
                            }
                        }} alt={''} className={this.props.classes.housesImage} style={housesImageDimensions}/> : null}
                    <Link to={'/properties/houses'} className={this.props.classes.houseIntroImageSurround}
                         style={this.getHousesIntroImageDimensions()}>
                        {this.props.hasHouses ?
                        <div className={this.props.classes.houseIntroHeadlineSurround}>
                            <Typography variant={'h2'} classes={{
                                root: classNames(this.props.classes.headline, this.props.classes.houseIntroHeadline)
                            }}>
                                {this.props.localize('home.references.house.intro.headline')}
                            </Typography>
                            <Typography variant={'caption'} classes={{
                                root: classNames(this.props.classes.caption, this.props.classes.houseIntroCaption)
                            }}>
                                <span className={this.props.classes.captionText}>
                                    {this.props.localize('home.references.caption')}
                                </span>
                                <span
                                    className={classNames(this.props.classes.captionDivider, this.props.classes.houseIntroCaptionDivider)}/>
                            </Typography>
                        </div>
                            : null}
                        <Image srcSet={{
                            webP: {
                                lg2x: housesIntroLGWebP2x,
                                lg: housesIntroLGWebP,
                                md2x: housesIntroMDWebP2x,
                                md: housesIntroMDWebP,
                                sm2x: housesIntroSMWebP2x,
                                sm: housesIntroSMWebP,
                                xs2x: housesIntroXSWebP2x,
                                xs: housesIntroXSWebP
                            },
                            fallback: {
                                lg2x: housesIntroLGJPG2x,
                                lg: housesIntroLGJPG,
                                md2x: housesIntroMDJPG2x,
                                md: housesIntroMDJPG,
                                sm2x: housesIntroSMJPG2x,
                                sm: housesIntroSMJPG,
                                xs2x: housesIntroXSJPG2x,
                                xs: housesIntroXSJPG
                            }
                        }} alt={''} className={this.props.classes.houseIntroImage}/>
                    </Link>
                </div>
                <div className={classNames(this.props.classes.section, this.props.classes.section2)}>
                    <Link to={'/properties/apartments'} className={this.props.classes.apartmentIntroImageSurround}
                         style={this.getApartmentIntroImageStyle()}>
                        <Image srcSet={{
                            webP: {
                                lg2x: apartmentsIntroLGWebP2x,
                                lg: apartmentsIntroLGWebP,
                                md2x: apartmentsIntroMDWebP2x,
                                md: apartmentsIntroMDWebP,
                                sm2x: apartmentsIntroSMWebP2x,
                                sm: apartmentsIntroSMWebP,
                                xs2x: apartmentsIntroXSWebP2x,
                                xs: apartmentsIntroXSWebP
                            },
                            fallback: {
                                lg2x: apartmentsIntroLGJPG2x,
                                lg: apartmentsIntroLGJPG,
                                md2x: apartmentsIntroMDJPG2x,
                                md: apartmentsIntroMDJPG,
                                sm2x: apartmentsIntroSMJPG2x,
                                sm: apartmentsIntroSMJPG,
                                xs2x: apartmentsIntroXSJPG2x,
                                xs: apartmentsIntroXSJPG
                            }
                        }} alt={''} className={this.props.classes.apartmentIntroImage}/>
                        {this.props.hasApartments ?
                        <div className={this.props.classes.apartmentIntroHeadlineSurround}>
                            <Typography variant={'h2'} classes={{
                                root: classNames(this.props.classes.headline, this.props.classes.houseIntroHeadline)
                            }}>
                                {this.props.localize('home.references.apartments.intro.headline')}
                            </Typography>
                            <Typography variant={'caption'} classes={{
                                root: classNames(this.props.classes.caption, this.props.classes.houseIntroCaption)
                            }}>
                                <span className={this.props.classes.captionText}>
                                    {this.props.localize('home.references.caption')}
                                </span>
                                <span
                                    className={classNames(this.props.classes.captionDivider, this.props.classes.houseIntroCaptionDivider)}/>
                            </Typography>
                        </div> : null}
                    </Link>
                    <Link to={'properties/references'} className={this.props.classes.referencesSurround}>
                        {this.props.hasReferences ?
                        <div className={classNames(this.props.classes.headlineSurround, this.props.classes.referencesHeadlineSurround)}>
                            <Typography variant={'h2'} classes={{
                                root: this.props.classes.headline
                            }}>
                                {this.props.localize('home.references.headline')}
                            </Typography>
                            <Typography variant={'caption'} classes={{
                                root: this.props.classes.caption
                            }}>
                                <span className={this.props.classes.captionText}>
                                    {this.props.localize('home.references.caption')}
                                </span>
                                <span className={this.props.classes.captionDivider}/>
                            </Typography>
                        </div> : null}
                        <Image srcSet={{
                            webP: {
                                lg2x: referencesIntroLGWebP2x,
                                lg: referencesIntroLGWebP,
                                md2x: referencesIntroMDWebP2x,
                                md: referencesIntroMDWebP,
                                sm2x: referencesIntroSMWebP2x,
                                sm: referencesIntroSMWebP,
                                xs2x: referencesIntroXSWebP2x,
                                xs: referencesIntroXSWebP
                            },
                            fallback: {
                                lg2x: referencesIntroLGJPG2x,
                                lg: referencesIntroLGJPG,
                                md2x: referencesIntroMDJPG2x,
                                md: referencesIntroMDJPG,
                                sm2x: referencesIntroSMJPG2x,
                                sm: referencesIntroSMJPG,
                                xs2x: referencesIntroXSJPG2x,
                                xs: referencesIntroXSJPG
                            }
                        }} alt={''} className={this.props.classes.referencesIntroImage} pictureClass={this.props.classes.referencesIntroPicture}/>
                    </Link>
                </div>
            </div>
        );
    }
}

export default withWidth()(withStyles(style)(withLocalization(References)));
