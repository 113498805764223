import React from 'react';
import {withLocalization} from 'react-localize';
import {withStyles} from '@material-ui/core/styles';
import withWidth, {isWidthDown} from '@material-ui/core/withWidth';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import {Link} from 'react-router-dom';
import classNames from 'classnames';
import type {DefaultComponentProps} from '../../../../types/Types';

import Image from '../../helper/Image';

import teamLGJPG from '../../../../images/dest/home/single_web-lg@1x.jpg';
import teamLGJPG2x from '../../../../images/dest/home/single_web-lg@2x.jpg';
import teamLGWebP from '../../../../images/dest/home/single_web-lg@1x.webp';
import teamLGWebP2x from '../../../../images/dest/home/single_web-lg@2x.webp';
import teamMDJPG from '../../../../images/dest/home/single_web-md@1x.jpg';
import teamMDJPG2x from '../../../../images/dest/home/single_web-md@2x.jpg';
import teamMDWebP from '../../../../images/dest/home/single_web-md@1x.webp';
import teamMDWebP2x from '../../../../images/dest/home/single_web-md@2x.webp';
import teamSMJPG from '../../../../images/dest/home/single_web-sm@1x.jpg';
import teamSMJPG2x from '../../../../images/dest/home/single_web-sm@2x.jpg';
import teamSMWebP from '../../../../images/dest/home/single_web-sm@1x.webp';
import teamSMWebP2x from '../../../../images/dest/home/single_web-sm@2x.webp';
import teamXSJPG from '../../../../images/dest/home/single_web-xs@1x.jpg';
import teamXSJPG2x from '../../../../images/dest/home/single_web-xs@2x.jpg';
import teamXSWebP from '../../../../images/dest/home/single_web-xs@1x.webp';
import teamXSWebP2x from '../../../../images/dest/home/single_web-xs@2x.webp';

import {DESKTOP_CONTENT_MAX_WIDTH} from '../../../../constants/constants';

type AboutUsProps = DefaultComponentProps & {}

const style: Function = (theme: Object): Object => ({
    surround: {
        width: '100%',
        position: 'relative'
    },
    root: {
        display: 'flex',
        maxWidth: DESKTOP_CONTENT_MAX_WIDTH,
        margin: '144px auto 0',
        justifyContent: 'space-between',
        alignItems: 'center',
        [theme.breakpoints.down('lg')]: {
            maxWidth: '90%'
        },
        [theme.breakpoints.down('sm')]: {
            flexWrap: 'wrap',
            maxWidth: '80%',
            marginTop: 26
        }
    },
    imageSurround: {
        width: '43%',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            margin: '0 auto'
        }
    },
    image: {
        width: '100%',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            margin: '0 auto'
        }
    },
    contentSurround: {
        width: '43%',
        [theme.breakpoints.down('sm')]: {
            width: '100%'
        }
    },
    headline: {
        color: '#171717',
        fontFamily: 'Playfair Display, sans-serif',
        fontWeight: 400,
        width: 400,
        [theme.breakpoints.down('sm')]: {
            width: '80%',
            margin: '0 auto 22px'
        }

    },
    content: {
        color: '#808080',
        marginTop: 20,
        [theme.breakpoints.down('sm')]: {
            textAlign: 'center',
            fontSize: 16
        }
    },
    button: {
        ...theme.button,
        marginTop: 47,
        [theme.breakpoints.down('sm')]: {
            margin: '27px auto 0',
            display: 'block'
        }
    },
    step: theme.step,
    link: theme.link
});

class AboutUs extends React.Component<AboutUsProps> {

    render() {
        return (
            <div className={this.props.classes.surround}>
                <div className={this.props.classes.step}>
                    01
                </div>
                <div className={this.props.classes.root}>
                    {isWidthDown('sm', this.props.width) ?
                        <Typography variant={'h2'} classes={{
                            root: classNames(this.props.classes.headline, this.props.classes.mobileHeadline)
                        }}>
                            {this.props.localize('home.about.us.headline')}
                        </Typography> : null}
                    <div className={this.props.classes.imageSurround}>
                        <Image srcSet={{
                            webP: {
                                lg2x: teamLGWebP2x,
                                lg: teamLGWebP,
                                md2x: teamMDWebP2x,
                                md: teamMDWebP,
                                sm2x: teamSMWebP2x,
                                sm: teamSMWebP,
                                xs2x: teamXSWebP2x,
                                xs: teamXSWebP
                            },
                            fallback: {
                                lg2x: teamLGJPG2x,
                                lg: teamLGJPG,
                                md2x: teamMDJPG2x,
                                md: teamMDJPG,
                                sm2x: teamSMJPG2x,
                                sm: teamSMJPG,
                                xs2x: teamXSJPG2x,
                                xs: teamXSJPG
                            }
                        }} alt={''} className={this.props.classes.image}/>
                    </div>
                    <div className={this.props.classes.contentSurround}>
                        {!isWidthDown('sm', this.props.width) ?
                            <Typography variant={'h2'} classes={{
                                root: this.props.classes.headline
                            }}>
                                {this.props.localize('home.about.us.headline')}
                            </Typography> : null}
                        <Typography variant={'body1'} classes={{
                            root: this.props.classes.content
                        }}>
                            {this.props.localize('home.about.us.content')}
                        </Typography>
                        <Button color={'primary'} className={this.props.classes.button}>
                            <Link className={this.props.classes.link} to={'/team'}>
                                {this.props.localize('home.about.us.button')}
                            </Link>
                        </Button>
                    </div>
                </div>
            </div>
        );
    }
}

export default withWidth()(withStyles(style)(withLocalization(AboutUs)));
