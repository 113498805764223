// @flow
import React from 'react';
import {withLocalization} from 'react-localize';
import {withStyles} from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import {DESKTOP_CONTENT_MAX_WIDTH} from '../../../../constants/constants';
import type {DefaultComponentProps} from '../../../../types/Types';
import BlogEntry from "./BlogEntry";

type BlogProps = DefaultComponentProps & {};

const style: Function = (theme: Object): Object => ({
    root: {
        position: 'relative',
        marginBottom: 190,
        [theme.breakpoints.down('sm')]: {
            marginBottom: 36,
            marginTop: -36,
        }
    },
    step: theme.step,
    contentSurround: {
        width: DESKTOP_CONTENT_MAX_WIDTH,
        margin: '0 auto',
        display: 'flex',
        [theme.breakpoints.down('lg')]: {
            width: '90%'
        },
        [theme.breakpoints.down('sm')]: {
            flexWrap: 'wrap'
        }
    },
    column: {
        width: '40%',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            marginBottom: 20
        }
    },
    posts: {
        width: '60%',
        [theme.breakpoints.down('sm')]: {
            width: '100%'
        }
    },
    headline: {
        fontFamily: 'Playfair Display',
        color: '#171717',
        width: 421,
        marginBottom: 20,
        [theme.breakpoints.down('sm')]: {
            textAlign: 'center',
            width: '80%',
            marginBottom: 14
        }
    },
    author: {
        marginBottom: 35,
        [theme.breakpoints.down('sm')]: {
            textAlign: 'center',
            marginBottom: 14
        }
    },
    block: {
        background: theme.palette.primary.main,
        height: 12,
        width: 22
    }
});

class Blog extends React.Component<BlogProps> {

    render() {
        return (
            <div className={this.props.classes.root}>
                <div className={this.props.classes.step}>
                    03
                </div>
                <div className={this.props.classes.contentSurround}>
                    <div className={this.props.classes.column}>
                        <Typography variant={'h2'} classes={{
                            root: this.props.classes.headline
                        }}>
                            {this.props.localize('home.blog.headline')}
                        </Typography>
                        <div className={this.props.classes.block} />
                    </div>
                    <div className={this.props.classes.posts}>
                        <BlogEntry headline={this.props.localize('home.blog.post.1.headline')} date={this.props.localize('home.blog.post.1.date')} content={this.props.localize('home.blog.post.1.content')} />
                        <BlogEntry headline={this.props.localize('home.blog.post.2.headline')} date={this.props.localize('home.blog.post.2.date')} content={this.props.localize('home.blog.post.2.content')} />
                    </div>
                </div>
            </div>
        );
    }
}

export default withStyles(style)(withLocalization(Blog));
