// @flow
import {connect} from 'react-redux';
import Home from '../presentational/sites/Home';
import PropertyType from '../presentational/helper/PropertyType';

const mapStateToProps = (state: Object): Object => ({
    hasApartments: state.application.grouped && state.application.grouped[PropertyType.APARTMENT] && state.application.properties ? state.application.grouped[PropertyType.APARTMENT].filter(id => !state.application.properties[id].sold).length > 0 : false,
    hasHouses: state.application.grouped && state.application.grouped[PropertyType.HOUSE] && state.application.properties ? state.application.grouped[PropertyType.HOUSE].filter(id => !state.application.properties[id].sold).length > 0 : false,
    hasReferences: state.application.properties && state.application.properties ? Object.keys(state.application.properties).filter(id => state.application.properties[id].sold).length > 0 : false
});

const mapDispatchToProps = (dispatch: Function): Object => ({
});


const HomeSite: Object = connect(mapStateToProps, mapDispatchToProps)(Home);

export default HomeSite;
