import React from 'react';
import {withLocalization} from 'react-localize';
import AppsIcon from '@material-ui/icons/Apps';
import NavigateBefore from '@material-ui/icons/NavigateBefore';
import Typography from '@material-ui/core/Typography';
import {withStyles} from '@material-ui/core/styles';
import classNames from 'classnames';
import logo from '../../../images/logo/logo.svg';
import logoInverted from '../../../images/logo/logo_inverted.svg';
import close from '../../../images/menu/x_web.svg';
import type {DefaultComponentProps} from '../../../types/Types';
import {withRouter} from 'react-router';

type HeaderProps = DefaultComponentProps & {
    toggleMenu: Function,
    menuOpen: boolean,
    shouldToggleBack: boolean,
    history: Object
}

const style: Function = (theme: Object): Object => ({
    root: {
        position: 'absolute',
        width: '100%',
        zIndex: 20
    },
    menuSurround: {
        top: 67,
        display: 'flex',
        left: '5%',
        position: 'fixed',
        cursor: 'pointer',
        alignItems: 'center',
        background: 'white',
        padding: '8px 12px',
        borderRadius: 1,
        transition: 'background 0.5s'
    },
    menuSurroundOpen: {
        background: 'none'
    },
    menuIcon: {
        color: theme.palette.secondary.dark
    },
    menu: {
        color: theme.palette.secondary.dark,
        marginLeft: 5
    },
    menuClose: {
        color: theme.palette.secondary.light,
        letterSpacing: '0.4px'
    },
    close: {
        color: theme.palette.secondary.contrastText,
        fontSize: 23
    },
    logo: {
        position: 'absolute',
        left: '50%',
        marginLeft: -87,
        top: 45,
        width: 202
    },
    logoFixed: {
        position: 'fixed'
    }
});

class Header extends React.Component<HeaderProps> {



    render() {
        const shouldToggleBack = this.props.location.pathname.startsWith('/property/');
        return (
            <div className={this.props.classes.root}>
                <div className={classNames(this.props.classes.menuSurround, this.props.menuOpen ? this.props.classes.menuSurroundOpen : '')} onClick={() => {
                    if (shouldToggleBack) {
                        this.props.history.push('/properties');
                    } else {
                        this.props.toggleMenu();
                    }
                }}>
                    {!this.props.menuOpen ?
                        [
                            shouldToggleBack? <NavigateBefore className={this.props.classes.menuIcon} /> : <AppsIcon className={this.props.classes.menuIcon}/>,
                            <Typography variant={'caption'}
                                        classes={{root: this.props.classes.menu}}>
                                {shouldToggleBack ? this.props.localize('back') : this.props.localize('menu')}
                            </Typography>]
                        : [
                            <img src={close} alt={''} />,
                            <Typography variant={'caption'} classes={{root: classNames(this.props.classes.menu, this.props.classes.menuClose)}}>
                                {this.props.localize('close.capital')}
                            </Typography>
                        ]}
                </div>
                <div className={classNames(this.props.classes.logo, this.props.menuOpen ? this.props.classes.logoFixed : '')}>
                    <img src={this.props.menuOpen ? logoInverted : logo} alt={''}/>
                </div>
            </div>
        );
    }
}

export default withRouter(withStyles(style)(withLocalization(Header)));
