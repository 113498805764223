// @flow
import {all, call, put, race, take, delay} from 'redux-saga/effects';
import ActionType from '../actions/ActionTypes';
import {createAction} from 'redux-actions';
import {fetchArray, Route} from '../api/Api';

function* fetchSaga(): Generator<any, any, any> {
    while (true) {
        yield race({
            delay: delay(60000),
            take: take(ActionType.LOAD_ESTATES)
        });
        yield put(createAction(ActionType.FETCH_ESTATES)());
        try {
            const properties: Array<Object> = yield call(fetchArray, process.env.REACT_APP_BASE_URL + Route.ESTATES);
            yield put(createAction(ActionType.FETCH_ESTATES_SUCCESS)(properties));
        } catch (e) {
            console.error(e);
            yield put(createAction(ActionType.FETCH_ESTATES_ERROR, error => ({error}))(e));
        }
    }
}

export default function* estateSaga(): Generator<any, any, any> {
    yield all([call(fetchSaga)]);
}