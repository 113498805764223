// @flow
import {handleActions} from 'redux-actions';
import type {ApplicationState} from '../types/Types';
import ActionTypes from '../actions/ActionTypes';
import FetchStatus from '../api/FetchStatus';

const initialState: ApplicationState = {
    language: {
        selected: 'de',
        available: ['de', 'en']
    },
    menu: {
        open: false
    },
    contact: {
        acceptPrivacyPolicy: false,
        name: '',
        email: '',
        message: '',
        fetchStatus: FetchStatus.SUCCESS
    },
    properties: {},
    lastOpenedProperty: -1,
    grouped: {}
};

export default handleActions({
        [ActionTypes.TOGGLE_MENU]: (state: ApplicationState) => ({
            ...state, ...{
                menu: {
                    open: state.menu ? !state.menu.open : true
                }
            }
        }),
        [ActionTypes.TOGGLE_DATA_PRIVACY_CHECKBOX]: (state: ApplicationState) => ({
            ...state, ...{
                contact: {
                    ...state.contact,
                    ...{
                        acceptPrivacyPolicy: state.contact ? !state.contact.acceptPrivacyPolicy : true
                    }
                }
            }
        }),
        [ActionTypes.UPDATE_CONTACT_MESSAGE_NAME]: (state: ApplicationState, action: Object) => ({
            ...state, ...{
                contact: {
                    ...state.contact,
                    ...{
                        name: action.payload ? action.payload.name : ''
                    }
                }
            }
        }),
        [ActionTypes.UPDATE_CONTACT_MESSAGE_E_MAIL]: (state: ApplicationState, action: Object) => ({
            ...state, ...{
                contact: {
                    ...state.contact,
                    ...{
                        email: action.payload ? action.payload.email : ''
                    }
                }
            }
        }),
        [ActionTypes.UPDATE_CONTACT_MESSAGE]: (state: ApplicationState, action: Object) => ({
            ...state, ...{
                contact: {
                    ...state.contact,
                    ...{
                        message: action.payload ? action.payload.message : ''
                    }
                }
            }
        }),
        [ActionTypes.FETCH_SEND_CONTACT_MESSAGE]: (state: ApplicationState) => ({
            ...state, ...{
                contact: {
                    ...state.contact,
                    ...{
                        fetchStatus: FetchStatus.ACTIVE
                    }
                }
            }
        }),
        [ActionTypes.FETCH_SEND_CONTACT_MESSAGE_SUCCESS]: (state: ApplicationState) => ({
            ...state, ...{
                contact: {
                    ...state.contact,
                    ...{
                        fetchStatus: FetchStatus.SUCCESS
                    }
                }
            }
        }),
        [ActionTypes.FETCH_SEND_CONTACT_MESSAGE_ERROR]: (state: ApplicationState) => ({
            ...state, ...{
                contact: {
                    ...state.contact,
                    ...{
                        fetchStatus: FetchStatus.ERROR
                    }
                }
            }
        }),
        [ActionTypes.FETCH_ESTATES_SUCCESS]: (state: ApplicationState, action: Object) => ({
            ...state,
            ...{
                properties: action.payload.properties,
                grouped: action.payload.grouped
            }
        }),
        [ActionTypes.SET_LAST_OPENED_PROPERTY]: (state: ApplicationState, action: Object) => ({
            ...state,
            ...{
                lastOpenedProperty: action.payload.propertyID
            }
        })
    },
    initialState);
