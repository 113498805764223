// @flow
import React from 'react';
import {withLocalization} from 'react-localize';
import {withStyles} from '@material-ui/core/styles';
import withWidth, {isWidthDown} from '@material-ui/core/withWidth';
import Typography from '@material-ui/core/Typography';
import type {DefaultComponentProps} from '../../../../types/Types';
import Collapse from '@material-ui/core/Collapse';
import {Button} from '@material-ui/core';

type BlogEntryProps = DefaultComponentProps & {
    headline: string,
    date: string,
    content: string
};

type BlogEntryState = {
    show: boolean
}

const style: Function = (theme: Object): Object => ({
    root: {
        marginBottom: 77,
        [theme.breakpoints.down('sm')]: {
            marginBottom: 37,
        }
    },
    headline: {
        marginBottom: 10,
        [theme.breakpoints.down('sm')]: {
            textAlign: 'center',
        }
    },
    date: {
        marginBottom: 25,
        [theme.breakpoints.down('sm')]: {
            textAlign: 'center',
            marginBottom: 10
        }
    },
    content: {
        marginBottom: 27,
        [theme.breakpoints.down('sm')]: {
            textAlign: 'center',
            marginBottom: 10
        }
    },
    button: {
        textTransform: 'none',
        fontSize: 20,
        fontWeight: 500,
        marginLeft: -8,
        [theme.breakpoints.down('sm')]: {
            display: 'block',
            margin: '0 auto'
        }
    }
});

class BlogEntry extends React.Component<BlogEntryProps, BlogEntryState> {

    constructor(props: BlogEntryProps) {
        super(props);
        this.state = {
            show: false
        }
    }

    render() {
        return (
            <div className={this.props.classes.root}>
                <Typography variant={'h3'} classes={{
                    root: this.props.classes.headline
                }}>
                    {this.props.headline}
                </Typography>
                <Typography variant={'h3'} classes={{
                    root: this.props.classes.date
                }}>
                    {this.props.date}
                </Typography>
                <Typography variant={'body1'} classes={{
                    root: this.props.classes.content
                }}>
                    <Collapse in={this.state.show} collapsedHeight={isWidthDown('lg', this.props.width) ? '76px' : '108px'}>
                        <div dangerouslySetInnerHTML={{__html: this.props.content}} />
                    </Collapse>
                </Typography>
                <Button color={'primary'} classes={{
                    root: this.props.classes.button
                }} onClick={() => this.setState({show: !this.state.show})}>
                    {!this.state.show ? this.props.localize('home.blog.more') : this.props.localize('home.blog.less')}
                </Button>
            </div>
        );
    }
}

export default withWidth()(withStyles(style)(withLocalization(BlogEntry)));
