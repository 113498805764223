// @flow
import {all, call, put, take, select} from 'redux-saga/effects';
import ActionType from '../actions/ActionTypes';
import {createAction} from 'redux-actions';
import {sendContactMessage, Route} from '../api/Api';

function* sendContactForm(): Generator<any, any, any> {
    while (true) {
        yield take(ActionType.SEND_CONTACT_MESSAGE);
        try {
            const {name, email, message, acceptPrivacyPolicy} = yield select((state: Object) => ({
                name: state.application.contact.name,
                email: state.application.contact.email,
                message: state.application.contact.message,
                acceptPrivacyPolicy: state.application.contact.acceptPrivacyPolicy
            }));
            if (name && email && message && acceptPrivacyPolicy) {
                yield put(createAction(ActionType.FETCH_SEND_CONTACT_MESSAGE)());
                yield call(sendContactMessage, process.env.REACT_APP_BASE_URL + Route.CONTACT, {
                    name: name,
                    email: email,
                    message: message,
                    acceptPrivacyPolicy: acceptPrivacyPolicy
                });
                yield put(createAction(ActionType.FETCH_SEND_CONTACT_MESSAGE_SUCCESS)());
            }
        } catch (e) {
            console.error(e);
            yield put(createAction(ActionType.FETCH_SEND_CONTACT_MESSAGE_ERROR)());
        }
    }
}

export default function* contactSaga(): Generator<any, any, any> {
    yield all([call(sendContactForm)]);
}