// @flow
import * as StatusCode from 'http-status-codes';

/**
 * Enumeration for the request method types which can be used.
 *
 * @type {{GET: string, POST: string, PUT: string, DELETE: string}}
 */
export const RequestMethod = {
    GET: 'GET',
    POST: 'POST',
    PUT: 'PUT',
    DELETE: 'DELETE'
};


/**
 * Checks whether the server has delivered a 200 response. If not so, an error is thrown.
 *
 * @param response The response of the server.
 * @return The response of the server as JSON.
 * @throws Error if the response of the server does not match OK (=200).
 */
export const checkServerResponse = (response: Object): Object => {
    if (response && response.status === StatusCode.OK) {
        return response.json();
    }
    throw new Error(response.statusText + '. Status Code: ' + response.status);
};

/**
 * Defines the header which should be used in each request.
 *
 * @return {{Accept: string, Content-Type: string}} An object which contains the headers which will be used in a request.
 */
export const defaultHeaders = (): Object => ({
    'Accept': 'application/json, text/plain, */*',
    'Content-Type': 'application/json'
});

/**
 * Helper method to get the default request configuration.
 *
 * @param method The used HTTP request method.
 * @param data The data to send in the body of the request.
 * @return {{method: *, headers: *, body: *}} The default request configuration.
 */
export const requestConfig = (method: any, data?: Object): Object => ({
    method: method,
    headers: defaultHeaders(),
    body: data ? JSON.stringify(data) : null
});
