import React from 'react';
import {withLocalization} from 'react-localize';
import {withStyles} from '@material-ui/core/styles';
import type {DefaultComponentProps} from '../../../../types/Types';
import Image from '../../helper/Image';
import withWidth, {isWidthDown} from '@material-ui/core/withWidth';
import slider1LGWebP2x from '../../../../images/dest/footer/slider_1-lg@2x.webp';
import slider1LGWebP from '../../../../images/dest/footer/slider_1-lg@1x.webp';
import slider1MDWebP2x from '../../../../images/dest/footer/slider_1-md@2x.webp';
import slider1MDWebP from '../../../../images/dest/footer/slider_1-md@1x.webp';
import slider1SMWebP2x from '../../../../images/dest/footer/slider_1-sm@2x.webp';
import slider1SMWebP from '../../../../images/dest/footer/slider_1-sm@1x.webp';
import slider1XSWebP2x from '../../../../images/dest/footer/slider_1-xs@2x.webp';
import slider1XSWebP from '../../../../images/dest/footer/slider_1-xs@1x.webp';
import slider1LGJPG2x from '../../../../images/dest/footer/slider_1-lg@2x.jpg';
import slider1LGJPG from '../../../../images/dest/footer/slider_1-lg@1x.jpg';
import slider1MDJPG2x from '../../../../images/dest/footer/slider_1-md@2x.jpg';
import slider1MDJPG from '../../../../images/dest/footer/slider_1-md@1x.jpg';
import slider1SMJPG2x from '../../../../images/dest/footer/slider_1-sm@2x.jpg';
import slider1SMJPG from '../../../../images/dest/footer/slider_1-sm@1x.jpg';
import slider1XSJPG2x from '../../../../images/dest/footer/slider_1-xs@2x.jpg';
import slider1XSJPG from '../../../../images/dest/footer/slider_1-xs@1x.jpg';

import slider2LGWebP2x from '../../../../images/dest/footer/slider_2-lg@2x.webp';
import slider2LGWebP from '../../../../images/dest/footer/slider_2-lg@1x.webp';
import slider2MDWebP2x from '../../../../images/dest/footer/slider_2-md@2x.webp';
import slider2MDWebP from '../../../../images/dest/footer/slider_2-md@1x.webp';
import slider2SMWebP2x from '../../../../images/dest/footer/slider_2-sm@2x.webp';
import slider2SMWebP from '../../../../images/dest/footer/slider_2-sm@1x.webp';
import slider2XSWebP2x from '../../../../images/dest/footer/slider_2-xs@2x.webp';
import slider2XSWebP from '../../../../images/dest/footer/slider_2-xs@1x.webp';
import slider2LGJPG2x from '../../../../images/dest/footer/slider_2-lg@2x.jpg';
import slider2LGJPG from '../../../../images/dest/footer/slider_2-lg@1x.jpg';
import slider2MDJPG2x from '../../../../images/dest/footer/slider_2-md@2x.jpg';
import slider2MDJPG from '../../../../images/dest/footer/slider_2-md@1x.jpg';
import slider2SMJPG2x from '../../../../images/dest/footer/slider_2-sm@2x.jpg';
import slider2SMJPG from '../../../../images/dest/footer/slider_2-sm@1x.jpg';
import slider2XSJPG2x from '../../../../images/dest/footer/slider_2-xs@2x.jpg';
import slider2XSJPG from '../../../../images/dest/footer/slider_2-xs@1x.jpg';

import slider3LGWebP2x from '../../../../images/dest/footer/slider_3-lg@2x.webp';
import slider3LGWebP from '../../../../images/dest/footer/slider_3-lg@1x.webp';
import slider3MDWebP2x from '../../../../images/dest/footer/slider_3-md@2x.webp';
import slider3MDWebP from '../../../../images/dest/footer/slider_3-md@1x.webp';
import slider3SMWebP2x from '../../../../images/dest/footer/slider_3-sm@2x.webp';
import slider3SMWebP from '../../../../images/dest/footer/slider_3-sm@1x.webp';
import slider3XSWebP2x from '../../../../images/dest/footer/slider_3-xs@2x.webp';
import slider3XSWebP from '../../../../images/dest/footer/slider_3-xs@1x.webp';
import slider3LGJPG2x from '../../../../images/dest/footer/slider_3-lg@2x.jpg';
import slider3LGJPG from '../../../../images/dest/footer/slider_3-lg@1x.jpg';
import slider3MDJPG2x from '../../../../images/dest/footer/slider_3-md@2x.jpg';
import slider3MDJPG from '../../../../images/dest/footer/slider_3-md@1x.jpg';
import slider3SMJPG2x from '../../../../images/dest/footer/slider_3-sm@2x.jpg';
import slider3SMJPG from '../../../../images/dest/footer/slider_3-sm@1x.jpg';
import slider3XSJPG2x from '../../../../images/dest/footer/slider_3-xs@2x.jpg';
import slider3XSJPG from '../../../../images/dest/footer/slider_3-xs@1x.jpg';

import slider4LGWebP2x from '../../../../images/dest/footer/slider_4-lg@2x.webp';
import slider4LGWebP from '../../../../images/dest/footer/slider_4-lg@1x.webp';
import slider4MDWebP2x from '../../../../images/dest/footer/slider_4-md@2x.webp';
import slider4MDWebP from '../../../../images/dest/footer/slider_4-md@1x.webp';
import slider4SMWebP2x from '../../../../images/dest/footer/slider_4-sm@2x.webp';
import slider4SMWebP from '../../../../images/dest/footer/slider_4-sm@1x.webp';
import slider4XSWebP2x from '../../../../images/dest/footer/slider_4-xs@2x.webp';
import slider4XSWebP from '../../../../images/dest/footer/slider_4-xs@1x.webp';
import slider4LGJPG2x from '../../../../images/dest/footer/slider_4-lg@2x.jpg';
import slider4LGJPG from '../../../../images/dest/footer/slider_4-lg@1x.jpg';
import slider4MDJPG2x from '../../../../images/dest/footer/slider_4-md@2x.jpg';
import slider4MDJPG from '../../../../images/dest/footer/slider_4-md@1x.jpg';
import slider4SMJPG2x from '../../../../images/dest/footer/slider_4-sm@2x.jpg';
import slider4SMJPG from '../../../../images/dest/footer/slider_4-sm@1x.jpg'
import slider4XSJPG2x from '../../../../images/dest/footer/slider_4-xs@2x.jpg';
import slider4XSJPG from '../../../../images/dest/footer/slider_4-xs@1x.jpg';

import slider5LGWebP2x from '../../../../images/dest/footer/slider_5-lg@2x.webp';
import slider5LGWebP from '../../../../images/dest/footer/slider_5-lg@1x.webp';
import slider5MDWebP2x from '../../../../images/dest/footer/slider_5-md@2x.webp';
import slider5MDWebP from '../../../../images/dest/footer/slider_5-md@1x.webp';
import slider5SMWebP2x from '../../../../images/dest/footer/slider_5-sm@2x.webp';
import slider5SMWebP from '../../../../images/dest/footer/slider_5-sm@1x.webp';
import slider5XSWebP2x from '../../../../images/dest/footer/slider_5-xs@2x.webp';
import slider5XSWebP from '../../../../images/dest/footer/slider_5-xs@1x.webp';
import slider5LGJPG2x from '../../../../images/dest/footer/slider_5-lg@2x.jpg';
import slider5LGJPG from '../../../../images/dest/footer/slider_5-lg@1x.jpg';
import slider5MDJPG2x from '../../../../images/dest/footer/slider_5-md@2x.jpg';
import slider5MDJPG from '../../../../images/dest/footer/slider_5-md@1x.jpg';
import slider5SMJPG2x from '../../../../images/dest/footer/slider_5-sm@2x.jpg';
import slider5SMJPG from '../../../../images/dest/footer/slider_5-sm@1x.jpg';
import slider5XSJPG2x from '../../../../images/dest/footer/slider_5-xs@2x.jpg';
import slider5XSJPG from '../../../../images/dest/footer/slider_5-xs@1x.jpg';

import slider6LGWebP2x from '../../../../images/dest/footer/slider_6-lg@2x.webp';
import slider6LGWebP from '../../../../images/dest/footer/slider_6-lg@1x.webp';
import slider6MDWebP2x from '../../../../images/dest/footer/slider_6-md@2x.webp';
import slider6MDWebP from '../../../../images/dest/footer/slider_6-md@1x.webp';
import slider6SMWebP2x from '../../../../images/dest/footer/slider_6-sm@2x.webp';
import slider6SMWebP from '../../../../images/dest/footer/slider_6-sm@1x.webp';
import slider6XSWebP2x from '../../../../images/dest/footer/slider_6-xs@2x.webp';
import slider6XSWebP from '../../../../images/dest/footer/slider_6-xs@1x.webp';
import slider6LGJPG2x from '../../../../images/dest/footer/slider_6-lg@2x.jpg';
import slider6LGJPG from '../../../../images/dest/footer/slider_6-lg@1x.jpg';
import slider6MDJPG2x from '../../../../images/dest/footer/slider_6-md@2x.jpg';
import slider6MDJPG from '../../../../images/dest/footer/slider_6-md@1x.jpg';
import slider6SMJPG2x from '../../../../images/dest/footer/slider_6-sm@2x.jpg';
import slider6SMJPG from '../../../../images/dest/footer/slider_6-sm@1x.jpg';
import slider6XSJPG2x from '../../../../images/dest/footer/slider_6-xs@2x.jpg';
import slider6XSJPG from '../../../../images/dest/footer/slider_6-xs@1x.jpg';

import slider7LGWebP2x from '../../../../images/dest/footer/slider_7-lg@2x.webp';
import slider7LGWebP from '../../../../images/dest/footer/slider_7-lg@1x.webp';
import slider7MDWebP2x from '../../../../images/dest/footer/slider_7-md@2x.webp';
import slider7MDWebP from '../../../../images/dest/footer/slider_7-md@1x.webp';
import slider7SMWebP2x from '../../../../images/dest/footer/slider_7-sm@2x.webp';
import slider7SMWebP from '../../../../images/dest/footer/slider_7-sm@1x.webp';
import slider7XSWebP2x from '../../../../images/dest/footer/slider_7-xs@2x.webp';
import slider7XSWebP from '../../../../images/dest/footer/slider_7-xs@1x.webp';
import slider7LGJPG2x from '../../../../images/dest/footer/slider_7-lg@2x.jpg';
import slider7LGJPG from '../../../../images/dest/footer/slider_7-lg@1x.jpg';
import slider7MDJPG2x from '../../../../images/dest/footer/slider_7-md@2x.jpg';
import slider7MDJPG from '../../../../images/dest/footer/slider_7-md@1x.jpg';
import slider7SMJPG2x from '../../../../images/dest/footer/slider_7-sm@2x.jpg';
import slider7SMJPG from '../../../../images/dest/footer/slider_7-sm@1x.jpg';
import slider7XSJPG2x from '../../../../images/dest/footer/slider_7-xs@2x.jpg';
import slider7XSJPG from '../../../../images/dest/footer/slider_7-xs@1x.jpg';

type ImagesProps = DefaultComponentProps & {}

type ImagesState = {
    marginBottom: number
}

const style: Function = (theme: Object): Object => ({
    root: {
        display: 'flex',
        margin: '0 auto',
        justifyContent: 'center',
        position: 'relative',
        zIndex: 2,
        width: '100%',
        transform: 'translateY(50%)',
        [theme.breakpoints.down('lg')]: {
            width: '95%'
        },
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            left: 0,
            marginTop: '-20vw'
        }
    },
    image: {
        [theme.breakpoints.down('lg')]: {
            width: '100%'
        }
    }
});

class Images extends React.Component<ImagesProps, ImagesState> {

    surround: HTMLElement;

    constructor(props: ImagesProps) {
        super(props);
        this.surround = React.createRef();
        this.state = {
            marginBottom: 0
        };
        window.addEventListener('resize', this.setImagesPosition.bind(this));
    }

    componentDidMount() {
        this.setImagesPosition();
    }

    setImagesPosition() {
        if (this.surround.current) {
            // Wait a small timeout for better positioning
            window.setTimeout(() => {
                this.setState({
                    marginBottom: -this.surround.current.offsetHeight / 2
                });
            }, 50);
        } else {
            this.setState({
                marginBottom: 0
            });
        }
    }

    render() {
        return (
            <div ref={this.surround} className={this.props.classes.root}>
                <Image srcSet={{
                    webP: {
                        lg2x: slider1LGWebP2x,
                        lg: slider1LGWebP,
                        md2x: slider1MDWebP2x,
                        md: slider1MDWebP,
                        sm2x: slider1SMWebP2x,
                        sm: slider1SMWebP,
                        xs2x: slider1XSWebP2x,
                        xs: slider1XSWebP
                    },
                    fallback: {
                        lg2x: slider1LGJPG2x,
                        lg: slider1LGJPG,
                        md2x: slider1MDJPG2x,
                        md: slider1MDJPG,
                        sm2x: slider1SMJPG2x,
                        sm: slider1SMJPG,
                        xs2x: slider1XSJPG2x,
                        xs: slider1XSJPG
                    }
                }} alt={''} className={this.props.classes.image}/>
                <Image srcSet={{
                    webP: {
                        lg2x: slider2LGWebP2x,
                        lg: slider2LGWebP,
                        md2x: slider2MDWebP2x,
                        md: slider2MDWebP,
                        sm2x: slider2SMWebP2x,
                        sm: slider2SMWebP,
                        xs2x: slider2XSWebP2x,
                        xs: slider2XSWebP
                    },
                    fallback: {
                        lg2x: slider2LGJPG2x,
                        lg: slider2LGJPG,
                        md2x: slider2MDJPG2x,
                        md: slider2MDJPG,
                        sm2x: slider2SMJPG2x,
                        sm: slider2SMJPG,
                        xs2x: slider2XSJPG2x,
                        xs: slider2XSJPG
                    }
                }} alt={''} className={this.props.classes.image}/>
                        <Image srcSet={{
                            webP: {
                                lg2x: slider3LGWebP2x,
                                lg: slider3LGWebP,
                                md2x: slider3MDWebP2x,
                                md: slider3MDWebP,
                                sm2x: slider3SMWebP2x,
                                sm: slider3SMWebP,
                                xs2x: slider3XSWebP2x,
                                xs: slider3XSWebP
                            },
                            fallback: {
                                lg2x: slider3LGJPG2x,
                                lg: slider3LGJPG,
                                md2x: slider3MDJPG2x,
                                md: slider3MDJPG,
                                sm2x: slider3SMJPG2x,
                                sm: slider3SMJPG,
                                xs2x: slider3XSJPG2x,
                                xs: slider3XSJPG
                            }
                        }} alt={''} className={this.props.classes.image}/>
                {!isWidthDown('sm', this.props.width) ?
                    <React.Fragment>
                        <Image srcSet={{
                            webP: {
                                lg2x: slider4LGWebP2x,
                                lg: slider4LGWebP,
                                md2x: slider4MDWebP2x,
                                md: slider4MDWebP,
                                sm2x: slider4SMWebP2x,
                                sm: slider4SMWebP,
                                xs2x: slider4XSWebP2x,
                                xs: slider4XSWebP
                            },
                            fallback: {
                                lg2x: slider4LGJPG2x,
                                lg: slider4LGJPG,
                                md2x: slider4MDJPG2x,
                                md: slider4MDJPG,
                                sm2x: slider4SMJPG2x,
                                sm: slider4SMJPG,
                                xs2x: slider4XSJPG2x,
                                xs: slider4XSJPG
                            }
                        }} alt={''} className={this.props.classes.image}/>,
                        <Image srcSet={{
                            webP: {
                                lg2x: slider5LGWebP2x,
                                lg: slider5LGWebP,
                                md2x: slider5MDWebP2x,
                                md: slider5MDWebP,
                                sm2x: slider5SMWebP2x,
                                sm: slider5SMWebP,
                                xs2x: slider5XSWebP2x,
                                xs: slider5XSWebP
                            },
                            fallback: {
                                lg2x: slider5LGJPG2x,
                                lg: slider5LGJPG,
                                md2x: slider5MDJPG2x,
                                md: slider5MDJPG,
                                sm2x: slider5SMJPG2x,
                                sm: slider5SMJPG,
                                xs2x: slider5XSJPG2x,
                                xs: slider5XSJPG
                            }
                        }} alt={''} className={this.props.classes.image}/>,
                        <Image srcSet={{
                            webP: {
                                lg2x: slider6LGWebP2x,
                                lg: slider6LGWebP,
                                md2x: slider6MDWebP2x,
                                md: slider6MDWebP,
                                sm2x: slider6SMWebP2x,
                                sm: slider6SMWebP,
                                xs2x: slider6XSWebP2x,
                                xs: slider6XSWebP
                            },
                            fallback: {
                                lg2x: slider6LGJPG2x,
                                lg: slider6LGJPG,
                                md2x: slider6MDJPG2x,
                                md: slider6MDJPG,
                                sm2x: slider6SMJPG2x,
                                sm: slider6SMJPG,
                                xs2x: slider6XSJPG2x,
                                xs: slider6XSJPG
                            }
                        }} alt={''} className={this.props.classes.image}/>,
                        <Image srcSet={{
                            webP: {
                                lg2x: slider7LGWebP2x,
                                lg: slider7LGWebP,
                                md2x: slider7MDWebP2x,
                                md: slider7MDWebP,
                                sm2x: slider7SMWebP2x,
                                sm: slider7SMWebP,
                                xs2x: slider7XSWebP2x,
                                xs: slider7XSWebP
                            },
                            fallback: {
                                lg2x: slider7LGJPG2x,
                                lg: slider7LGJPG,
                                md2x: slider7MDJPG2x,
                                md: slider7MDJPG,
                                sm2x: slider7SMJPG2x,
                                sm: slider7SMJPG,
                                xs2x: slider7XSJPG2x,
                                xs: slider7XSJPG
                            }
                        }} alt={''} className={this.props.classes.image}/>
                    </React.Fragment>
                    : null}
            </div>
        );
    }
}

export default withWidth()(withStyles(style)(withLocalization(Images)));