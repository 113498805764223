// @flow
import {createMuiTheme} from '@material-ui/core/styles';

/************************************************************************************************
 * Create the Qualitize material ui theme. Defines the main colors and typography of the app.
 *************************************************************************************************/


let theme: Object = createMuiTheme({});
theme = createMuiTheme({
    ...theme, ...{
        palette: {
            primary: {
                light: '#7C7C7C',
                main: '#B51F29',
                dark: '#30373F',
                contrastText: '#ffffff',
            },
            secondary: {
                light: '#D7D7D7',
                main: '#FF2C3A',
                dark: '#23262F',
                contrastText: '#ffffff',
            }
        },
        typography: {
            useNextVariants: true,
            fontFamily: [
                'Montserrat',
                'sans-serif'
            ],
            caption: {
                fontSize: 16,
                textTransform: 'uppercase',
                fontWeight: 500
            },

            h1: {
                color: '#171717',
                fontFamily: 'Playfair Display',
                fontSize: 60,
                lineHeight: '75px',
                [theme.breakpoints.down('sm')]: {
                    fontSize: 35,
                    lineHeight: '45px',
                }
            },
            h2: {
                fontSize: 40,
                lineHeight: '48px',
                letterSpacing: '1px',
                [theme.breakpoints.down('sm')]: {
                    letterSpacing: '-0.5px',
                    fontSize: 32,
                    lineHeight: '42px',
                }
            },
            h3: {
                color: '#23262F',
                fontSize: 26,
                fontWeight: 500,
                lineHeight: 1,
                [theme.breakpoints.down('sm')]: {
                    fontSize: 20,
                    lineHeight: '28px',
                }
            },
            body1: {
                fontSize: 20,
                lineHeight: '36px',
                color: '#808080',
                [theme.breakpoints.down('lg')]: {
                    fontSize: 18,
                    lineHeight: '28px'
                },
                [theme.breakpoints.down('sm')]: {
                    fontSize: 16,
                    lineHeight: '26px'
                }
            }
        },
        link: {
            color: 'inherit',
            textDecoration: 'none',
            cursor: 'pointer'
        },
        button: {
            background: '#B51F29',
            color: 'white',
            borderRadius: 0,
            fontSize: 15,
            padding: 15
        },
        step: {
            position: 'absolute',
            color: '#B51F29',
            top: 0,
            transform: 'rotate(-270deg)',
            fontSize: 120,
            fontFamily: 'Playfair Display',
            letterSpacing: '3.75px',
            left: -30,
            zIndex: 5,
            [theme.breakpoints.down('sm')]: {
                fontSize: 80,
                letterSpacing: '2.5px',
                left: -25
            }
        },
        fullSizeImage: {
            minHeight: '100%',
            minWidth: '100%',
            height: 'auto',
            width: 'auto',
            position: 'absolute',
            top: '-100%',
            bottom: '-100%',
            left: '-100%',
            right: '-100%',
            margin: 'auto',
            zIndex: 1
        }
    }
});

export default theme;
