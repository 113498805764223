import React from 'react';
import {withLocalization} from 'react-localize';
import {withStyles} from '@material-ui/core/styles';
import Hero from './home/Hero';
import AboutUs from './home/AboutUs';
import References from './home/References';
import Blog from './home/Blog';
import Divider from './home/Divider';
import Slider from './home/Slider';
import Images from './home/Images';
import type {DefaultComponentProps} from '../../../types/Types';

type HomeProps = DefaultComponentProps & {
    hasReferences: boolean,
    hasApartments: boolean,
    hasHouses: boolean
}

const style: Function = (theme: Object): Object => ({
    root: {
    }
});

class Home extends React.Component<HomeProps> {

    componentDidMount() {
        document.title = process.env.REACT_APP_TITLE
    }

    render() {
        return (
            <div className={this.props.classes.root}>
                <Hero />
                <AboutUs />
                <References hasReferences={this.props.hasReferences}
                            hasHouses={this.props.hasHouses}
                            hasApartments={this.props.hasApartments}/>
                <Blog />
                <Divider />
                <Slider />
                <Images/>
            </div>
        );
    }
}

export default withStyles(style)(withLocalization(Home));