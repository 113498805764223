// @flow
import React, {Component} from 'react';
import {withLocalization} from 'react-localize';
import Typography from '@material-ui/core/Typography';
import {withStyles} from '@material-ui/core/styles';
import classNames from 'classnames';
import type {DefaultComponentProps} from '../../../types/Types';
import {withRouter} from 'react-router';
import {Link} from 'react-router-dom';
import Image from '../helper/Image';

import homeLGJPG from '../../../images/dest/menu/home_web-lg@1x.jpg';
import homeLGJPG2x from '../../../images/dest/menu/home_web-lg@2x.jpg';
import homeLGWebP from '../../../images/dest/menu/home_web-lg@1x.webp';
import homeLGWebP2x from '../../../images/dest/menu/home_web-lg@2x.webp';
import homeMDJPG from '../../../images/dest/menu/home_web-md@1x.jpg';
import homeMDJPG2x from '../../../images/dest/menu/home_web-md@2x.jpg';
import homeMDWebP from '../../../images/dest/menu/home_web-md@1x.webp';
import homeMDWebP2x from '../../../images/dest/menu/home_web-md@2x.webp';
import homeSMJPG from '../../../images/dest/menu/home_web-sm@1x.jpg';
import homeSMJPG2x from '../../../images/dest/menu/home_web-sm@2x.jpg';
import homeSMWebP from '../../../images/dest/menu/home_web-sm@1x.webp';
import homeSMWebP2x from '../../../images/dest/menu/home_web-sm@2x.webp';
import homeXSJPG from '../../../images/dest/menu/home_web-xs@1x.jpg';
import homeXSJPG2x from '../../../images/dest/menu/home_web-xs@2x.jpg';
import homeXSWebP from '../../../images/dest/menu/home_web-xs@1x.webp';
import homeXSWebP2x from '../../../images/dest/menu/home_web-xs@2x.webp';

import realEstateLGJPG from '../../../images/dest/menu/real_estate_web-lg@1x.jpg';
import realEstateLGJPG2x from '../../../images/dest/menu/real_estate_web-lg@2x.jpg';
import realEstateLGWebP from '../../../images/dest/menu/real_estate_web-lg@1x.webp';
import realEstateLGWebP2x from '../../../images/dest/menu/real_estate_web-lg@2x.webp';
import realEstateMDJPG from '../../../images/dest/menu/real_estate_web-md@1x.jpg';
import realEstateMDJPG2x from '../../../images/dest/menu/real_estate_web-md@2x.jpg';
import realEstateMDWebP from '../../../images/dest/menu/real_estate_web-md@1x.webp';
import realEstateMDWebP2x from '../../../images/dest/menu/real_estate_web-md@2x.webp';
import realEstateSMJPG from '../../../images/dest/menu/real_estate_web-sm@1x.jpg';
import realEstateSMJPG2x from '../../../images/dest/menu/real_estate_web-sm@2x.jpg';
import realEstateSMWebP from '../../../images/dest/menu/real_estate_web-sm@1x.webp';
import realEstateSMWebP2x from '../../../images/dest/menu/real_estate_web-sm@2x.webp';
import realEstateXSJPG from '../../../images/dest/menu/real_estate_web-xs@1x.jpg';
import realEstateXSJPG2x from '../../../images/dest/menu/real_estate_web-xs@2x.jpg';
import realEstateXSWebP from '../../../images/dest/menu/real_estate_web-xs@1x.webp';
import realEstateXSWebP2x from '../../../images/dest/menu/real_estate_web-xs@2x.webp';

import teamLGJPG from '../../../images/dest/menu/single_web-lg@1x.jpg';
import teamLGJPG2x from '../../../images/dest/menu/single_web-lg@2x.jpg';
import teamLGWebP from '../../../images/dest/menu/single_web-lg@1x.webp';
import teamLGWebP2x from '../../../images/dest/menu/single_web-lg@2x.webp';
import teamMDJPG from '../../../images/dest/menu/single_web-md@1x.jpg';
import teamMDJPG2x from '../../../images/dest/menu/single_web-md@2x.jpg';
import teamMDWebP from '../../../images/dest/menu/single_web-md@1x.webp';
import teamMDWebP2x from '../../../images/dest/menu/single_web-md@2x.webp';
import teamSMJPG from '../../../images/dest/menu/single_web-sm@1x.jpg';
import teamSMJPG2x from '../../../images/dest/menu/single_web-sm@2x.jpg';
import teamSMWebP from '../../../images/dest/menu/single_web-sm@1x.webp';
import teamSMWebP2x from '../../../images/dest/menu/single_web-sm@2x.webp';
import teamXSJPG from '../../../images/dest/menu/single_web-xs@1x.jpg';
import teamXSJPG2x from '../../../images/dest/menu/single_web-xs@2x.jpg';
import teamXSWebP from '../../../images/dest/menu/single_web-xs@1x.webp';
import teamXSWebP2x from '../../../images/dest/menu/single_web-xs@2x.webp';

import officeLGJPG from '../../../images/dest/menu/office_web-lg@1x.jpg';
import officeLGJPG2x from '../../../images/dest/menu/office_web-lg@2x.jpg';
import officeLGWebP from '../../../images/dest/menu/office_web-lg@1x.webp';
import officeLGWebP2x from '../../../images/dest/menu/office_web-lg@2x.webp';
import officeMDJPG from '../../../images/dest/menu/office_web-md@1x.jpg';
import officeMDJPG2x from '../../../images/dest/menu/office_web-md@2x.jpg';
import officeMDWebP from '../../../images/dest/menu/office_web-md@1x.webp';
import officeMDWebP2x from '../../../images/dest/menu/office_web-md@2x.webp';
import officeSMJPG from '../../../images/dest/menu/office_web-sm@1x.jpg';
import officeSMJPG2x from '../../../images/dest/menu/office_web-sm@2x.jpg';
import officeSMWebP from '../../../images/dest/menu/office_web-sm@1x.webp';
import officeSMWebP2x from '../../../images/dest/menu/office_web-sm@2x.webp';
import officeXSJPG from '../../../images/dest/menu/office_web-xs@1x.jpg';
import officeXSJPG2x from '../../../images/dest/menu/office_web-xs@2x.jpg';
import officeXSWebP from '../../../images/dest/menu/office_web-sm@1x.webp';
import officeXSWebP2x from '../../../images/dest/menu/office_web-xs@2x.webp';

const OPACITY_ANIMATION_DURATION = 300;

type MenuProps = DefaultComponentProps & {
    open: boolean,
    toggleMenu: Function,
    location: Object
};

type MenuState = {
    activeLink: string,
    opacity: Object
}

const style: Function = (theme: Object) => ({
    surround: {
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        background: theme.palette.primary.dark,
        display: 'flex',
        zIndex: 19,
        opacity: 0,
        visibility: 'hidden',
        transition: 'opacity 0.5s, visibility 0s 0.5s',
        alignItems: 'center'
    },
    opened: {
        opacity: 1,
        visibility: 'visible',
        transition: 'opacity 0.5s'
    },
    menuItem: {
        color: theme.palette.primary.contrastText,
        fontFamily: 'Playfair Display',
        fontSize: 80,
        lineHeight: 1,
        marginBottom: 30,
        marginLeft: '20%',
        transition: 'margin 0.5s',
        [theme.breakpoints.down('lg')]: {
            fontSize: 50,
            lineHeight: '65px',
            marginBottom: 10,
        }
    },
    menuItemOpened: {
        marginBottom: 70,
        [theme.breakpoints.down('lg')]: {
            marginBottom: 30,
        }
    },
    menuItemActive: {
        color: theme.palette.secondary.main
    },
    link: theme.link,
    contentSurround: {
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 9
    },
    imageSurround: {
        width: 1050,
        position: 'relative',
        [theme.breakpoints.down('lg')]: {
            width: 800
        }
    },
    image: {
        width: 1050,
        transition: `all ${OPACITY_ANIMATION_DURATION}ms linear`,
        position: 'absolute',
        top: 0,
        left: 0,
        [theme.breakpoints.down('lg')]: {
            width: 800
        }
    },
    imageFirst: {
        position: 'relative'
    },
    content: {
        display: 'flex',
        marginTop: 17,
        justifyContent: 'center'
    },
    contentElement: {
        color: theme.palette.primary.contrastText,
        fontWeight: 300,
        marginRight: 0
    },
    contentSeparator: {
        color: theme.palette.secondary.main,
        fontWeight: 700,
        marginRight: 5,
        marginLeft: 5,
    },
    menu: {
        position: 'absolute',
        zIndex: 10,
        perspective: 1200,
        top: '50%',
        transform: 'translateY(-50%)',
        height: 600,
        [theme.breakpoints.down('lg')]: {
            height: 380
        }
    },
    anchorSurround: {
        opacity: 0.4,
        transform: 'rotateX(35deg) translateY(-25%)',
        transition: 'transform 0.5s, opacity 0.5s'
    },
    anchorSurroundOpened: {
        opacity: 1,
        transform: 'rotateX(0deg)'
    }
});

/**
 * Helper Component which scrolls the page to the top if the location has changed.
 */
class Menu extends Component<MenuProps, MenuState> {


    constructor(props: MenuProps) {
        super(props);
        this.state = {
            activeLink: props.location.pathname,
            opacity: Menu.getOpacity(props.location.pathname)
        };
    }

    static getOpacity(path: string) {
        return {
            '/': path === '/' ? 1 : 0,
            '/properties': path === '/properties' ? 1 : 0,
            '/team': path === '/team' ? 1 : 0,
            '/contact': path === '/contact' ? 1 : 0,
        }
    }

    handleImageChange(path: string) {
        if (path !== this.state.activeLink) {
            this.setState({
                opacity: Menu.getOpacity(path),
                activeLink: path
            });
        }
    }

    render() {
        return (
            <div className={classNames(this.props.classes.surround, this.props.open ? this.props.classes.opened : '')}>
                <div className={this.props.classes.contentSurround}>
                    <div className={this.props.classes.imageSurround}>
                        <Image forceLoad={true} srcSet={{
                            webP: {
                                lg2x: homeLGWebP2x,
                                lg: homeLGWebP,
                                md2x: homeMDWebP2x,
                                md: homeMDWebP,
                                sm2x: homeSMWebP2x,
                                sm: homeSMWebP,
                                xs2x: homeXSWebP2x,
                                xs: homeXSWebP
                            },
                            fallback: {
                                lg2x: homeLGJPG2x,
                                lg: homeLGJPG,
                                md2x: homeMDJPG2x,
                                md: homeMDJPG,
                                sm2x: homeSMJPG2x,
                                sm: homeSMJPG,
                                xs2x: homeXSJPG2x,
                                xs: homeXSJPG
                            }
                        }} alt={''} className={classNames(this.props.classes.image, this.props.classes.imageFirst)}
                               style={{
                                   opacity: this.state.opacity['/']
                               }}/>
                        <Image forceLoad={true} srcSet={{
                            webP: {
                                lg2x: realEstateLGWebP2x,
                                lg: realEstateLGWebP,
                                md2x: realEstateMDWebP2x,
                                md: realEstateMDWebP,
                                sm2x: realEstateSMWebP2x,
                                sm: realEstateSMWebP,
                                xs2x: realEstateXSWebP2x,
                                xs: realEstateXSWebP
                            },
                            fallback: {
                                lg2x: realEstateLGJPG2x,
                                lg: realEstateLGJPG,
                                md2x: realEstateMDJPG2x,
                                md: realEstateMDJPG,
                                sm2x: realEstateSMJPG2x,
                                sm: realEstateSMJPG,
                                xs2x: realEstateXSJPG2x,
                                xs: realEstateXSJPG
                            }
                        }} alt={''} className={this.props.classes.image} style={{
                            opacity: this.state.opacity['/properties']
                        }}/>
                        <Image forceLoad={true} srcSet={{
                            webP: {
                                lg2x: teamLGWebP2x,
                                lg: teamLGWebP,
                                md2x: teamMDWebP2x,
                                md: teamMDWebP,
                                sm2x: teamSMWebP2x,
                                sm: teamSMWebP,
                                xs2x: teamXSWebP2x,
                                xs: teamXSWebP
                            },
                            fallback: {
                                lg2x: teamLGJPG2x,
                                lg: teamLGJPG,
                                md2x: teamMDJPG2x,
                                md: teamMDJPG,
                                sm2x: teamSMJPG2x,
                                sm: teamSMJPG,
                                xs2x: teamXSJPG2x,
                                xs: teamXSJPG
                            }
                        }} alt={''} className={this.props.classes.image} style={{
                            opacity: this.state.opacity['/team']
                        }}/>
                        <Image forceLoad={true} srcSet={{
                            webP: {
                                lg2x: officeLGWebP2x,
                                lg: officeLGWebP,
                                md2x: officeMDWebP2x,
                                md: officeMDWebP,
                                sm2x: officeSMWebP2x,
                                sm: officeSMWebP,
                                xs2x: officeXSWebP2x,
                                xs: officeXSWebP
                            },
                            fallback: {
                                lg2x: officeLGJPG2x,
                                lg: officeLGJPG,
                                md2x: officeMDJPG2x,
                                md: officeMDJPG,
                                sm2x: officeSMJPG2x,
                                sm: officeSMJPG,
                                xs2x: officeXSJPG2x,
                                xs: officeXSJPG
                            }
                        }} alt={''} className={this.props.classes.image} style={{
                            opacity: this.state.opacity['/contact']
                        }}/>
                        <div className={this.props.classes.content}>
                            <Typography variant={'body1'} classes={{
                                root: this.props.classes.contentElement
                            }}>
                                {this.props.localize('footer.legal.name')}
                            </Typography>
                                <Typography variant={'body1'} classes={{
                                    root: this.props.classes.contentSeparator
                                }}>
                                    {this.props.localize('footer.separator')}
                                </Typography>
                            <Typography variant={'body1'} classes={{
                                root: this.props.classes.contentElement
                            }}>
                                {this.props.localize('footer.legal.street')}
                            </Typography>
                                <Typography variant={'body1'} classes={{
                                    root: this.props.classes.contentSeparator
                                }}>
                                    {this.props.localize('footer.separator')}
                                </Typography>
                            <Typography variant={'body1'} classes={{
                                root: this.props.classes.contentElement
                            }}>
                                {this.props.localize('footer.legal.city')}
                            </Typography>
                        </div>
                    </div>
                </div>
                <div className={this.props.classes.menu}>
                    <div className={classNames(this.props.classes.anchorSurround, this.props.open ? this.props.classes.anchorSurroundOpened : '')}>
                        <Typography variant={'h1'} classes={{
                            root: classNames(this.props.classes.menuItem, this.props.open ? this.props.classes.menuItemOpened : '', this.state.activeLink === '/' ? this.props.classes.menuItemActive : '')
                        }} onMouseEnter={() => this.handleImageChange('/')}>
                            <Link className={this.props.classes.link} to={'/'}
                                  onClick={this.props.toggleMenu.bind(this)}>
                                {this.props.localize('menu.home')}.
                            </Link>
                        </Typography>
                        <Typography variant={'h1'} classes={{
                            root: classNames(this.props.classes.menuItem, this.props.open ? this.props.classes.menuItemOpened : '', this.state.activeLink === '/properties' ? this.props.classes.menuItemActive : '')
                        }} onMouseEnter={() => this.handleImageChange('/properties')}>
                            <Link className={this.props.classes.link} to={'/properties'}
                                  onClick={this.props.toggleMenu.bind(this)}>
                                {this.props.localize('menu.properties')}.
                            </Link>
                        </Typography>
                        <Typography variant={'h1'} classes={{
                            root: classNames(this.props.classes.menuItem, this.props.open ? this.props.classes.menuItemOpened : '', this.state.activeLink === '/team' ? this.props.classes.menuItemActive : '')
                        }} onMouseEnter={() => this.handleImageChange('/team')}>
                            <Link className={this.props.classes.link} to={'/team'}
                                  onClick={this.props.toggleMenu.bind(this)}>
                                {this.props.localize('menu.team')}.
                            </Link>
                        </Typography>
                        <Typography variant={'h1'} classes={{
                            root: classNames(this.props.classes.menuItem, this.props.open ? this.props.classes.menuItemOpened : '', this.state.activeLink === '/contact' ? this.props.classes.menuItemActive : '')
                        }} onMouseEnter={() => this.handleImageChange('/contact')}>
                            <Link className={this.props.classes.link} to={'/contact'}
                                  onClick={this.props.toggleMenu.bind(this)}>
                                {this.props.localize('menu.contact')}.
                            </Link>
                        </Typography>
                    </div>
                </div>
            </div>
        )
    }
}

export default withRouter(withLocalization(withStyles(style)(Menu)));
