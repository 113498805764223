// @flow
import React, {Component} from 'react';
import type {Dictionary} from '../../../types/Types';
import TrackVisibility from 'react-on-screen';

type ImageProps = {
    srcSet: Dictionary<string, Dictionary<string, string>>,
    alt: string,
    className?: string,
    pictureClass?: string,
    style?: Object,
    ref?: Object,
    forceLoad?: boolean,
    surroundClassName?: string
}

/**
 * Helper Component which scrolls the page to the top if the location has changed.
 */
class Image extends Component<ImageProps> {


    render() {
        return (
            <TrackVisibility className={this.props.surroundClassName} offset={500} partialVisibility={true} once={true}>
                {({isVisible}) => isVisible || this.props.forceLoad ?
                    <picture className={this.props.pictureClass}>
                        <source srcSet={`${this.props.srcSet.webP.lg2x} 2x, ${this.props.srcSet.webP.lg} 1x`}
                                type={'image/webp'}
                                media={'(min-width: 900px)'}/>
                        <source srcSet={`${this.props.srcSet.webP.md2x} 2x, ${this.props.srcSet.webP.md} 1x`}
                                type={'image/webp'}
                                media={'(min-width: 601px)'}/>
                        <source srcSet={`${this.props.srcSet.webP.sm2x} 2x, ${this.props.srcSet.webP.sm} 1x`}
                                type={'image/webp'}
                                media={'(min-width: 451px)'}/>
                        <source srcSet={`${this.props.srcSet.webP.xs2x} 2x, ${this.props.srcSet.webP.xs} 1x`}
                                type={'image/webp'}
                                media={'(max-width: 450px)'}/>
                        <source srcSet={`${this.props.srcSet.fallback.lg2x} 2x, ${this.props.srcSet.fallback.lg} 1x`}
                                media={'(min-width: 900px)'}/>
                        <source srcSet={`${this.props.srcSet.fallback.md2x} 2x, ${this.props.srcSet.fallback.md} 1x`}
                                media={'(min-width: 601px)'}/>
                        <source srcSet={`${this.props.srcSet.fallback.sm2x} 2x, ${this.props.srcSet.fallback.sm} 1x`}
                                media={'(min-width: 451px)'}/>
                        <source srcSet={`${this.props.srcSet.fallback.xs2x} 2x, ${this.props.srcSet.fallback.xs} 1x`}
                                media={'(max-width: 450px)'}/>
                        <img className={this.props.className} src={this.props.srcSet.fallback.lg}
                             alt={this.props.alt} style={this.props.style} ref={this.props.ref}/>
                    </picture>
                    : null}
            </TrackVisibility>
        )
    }
}

export default Image;
