export default {
    TOGGLE_MENU: 'MENU/TOGGLE',
    TOGGLE_DATA_PRIVACY_CHECKBOX: 'CONTACT/TOGGLE_DATA_PRIVACY_CHECKBOX',
    RESET_CONTACT_DATA: 'CONTACT/RESET_CONTACT_DATA',
    UPDATE_CONTACT_MESSAGE_NAME: 'CONTACT/UPDATE_CONTACT_MESSAGE_NAME',
    UPDATE_CONTACT_MESSAGE_E_MAIL: 'CONTACT/UPDATE_CONTACT_MESSAGE_E_MAIL',
    UPDATE_CONTACT_MESSAGE: 'CONTACT/UPDATE_CONTACT_MESSAGE',
    FETCH_SEND_CONTACT_MESSAGE: 'CONTACT/FETCH_SEND_CONTACT_MESSAGE',
    FETCH_SEND_CONTACT_MESSAGE_SUCCESS: 'CONTACT/FETCH_SEND_CONTACT_MESSAGE_SUCCESS',
    FETCH_SEND_CONTACT_MESSAGE_ERROR: 'CONTACT/FETCH_SEND_CONTACT_MESSAGE_ERROR',
    SEND_CONTACT_MESSAGE: 'CONTACT/SEND_CONTACT_MESSAGE',
    LOAD_ESTATES: 'ESTATES/LOAD_ESTATES',
    FETCH_ESTATES: 'ESTATES/FETCH_ESTATES',
    FETCH_ESTATES_SUCCESS: 'ESTATES/FETCH_ESTATES_SUCCESS',
    FETCH_ESTATES_ERROR: 'ESTATES/FETCH_ESTATES_ERROR',
    SET_LAST_OPENED_PROPERTY: 'ESTATES/SET_LAST_OPENED_PROPERTY'
};