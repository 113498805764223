import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import {createStore, applyMiddleware, combineReducers} from 'redux';
import {Provider} from 'react-redux';
import * as serviceWorker from './serviceWorker';
import localforage from 'localforage';
import {persistReducer, persistStore} from 'redux-persist';
import {PersistGate} from 'redux-persist/integration/react';
import {createCookieMiddleware} from 'redux-cookie';
import cookies from 'js-cookie';
import createSagaMiddleware from 'redux-saga';
import application from './reducer/application';
import WebApp from './components/container/WebApp';
import AppSaga from './saga/AppSaga';
import 'react-bnb-gallery/dist/style.css'

/************************************************************************************************
 * 1. Web app database configuration
 *
 * [LocalForage]{@link https://github.com/localForage/localForage} is used for the storage of
 * the reducer in a web database. Keep in mind, that reducer which should not be persisted
 * must be added to the blacklist of the DATABASE_CONFIG constant.
 * The database name is passed via the environment variables.
 ************************************************************************************************/

localforage.config({
    name: process.env.REACT_APP_DATABASE,
    version: 1.0,
    size: 4980736,
    storeName: process.env.REACT_APP_DATABASE
});

const DATABASE_CONFIG = {
    key: process.env.REACT_APP_DATABASE,
    storage: localforage,
    /********************************************************************************************
     * ADD REDUCER WHICH SHOULD NOT BE PERSISTED HERE!
     ********************************************************************************************/
    blacklist: ['']
};


/************************************************************************************************
 * 2. Sagas
 ************************************************************************************************/

const sagaMiddleware = createSagaMiddleware();

/************************************************************************************************
 * 3. Create reducer
 *
 * All reducer must be registered here. Reducer can be made persistent via
 * [redux-persist]{@link https://github.com/rt2zz/redux-persist}. Per default, the connection
 * reducer is added to the reducers.
 ************************************************************************************************/

const reducer = persistReducer(DATABASE_CONFIG,
    combineReducers({
        application: application
    }));

/************************************************************************************************
 * 4. Create middleware
 *
 * All redux middleware which should run must be registered here.
 * Default: Redux Saga, Cookie middleware and Web Socket middleware
 ************************************************************************************************/

const middleware = applyMiddleware(sagaMiddleware, createCookieMiddleware(cookies));

/************************************************************************************************
 * 5. Create redux store
 ************************************************************************************************/

const store = createStore(reducer, middleware);


/************************************************************************************************
 * 6. Run root saga
 *
 * The saga middleware must be created before the store is created, but the saga itself must
 * be run after the store creation.
 ************************************************************************************************/

sagaMiddleware.run(AppSaga);

/************************************************************************************************
 * 7. Render web app
 *
 * The WebApp is surrounded by the redux provider component and the PersistGate, which rehydrates
 * the store after the page is loaded.
 ************************************************************************************************/

ReactDOM.render(
    <Provider store={store}>
        <PersistGate persistor={persistStore(store, null)}>
            <WebApp/>
        </PersistGate>
    </Provider>,
    document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
