import React from 'react';
import {withLocalization} from 'react-localize';
import {withStyles} from '@material-ui/core/styles';
import withWidth, {isWidthDown} from '@material-ui/core/withWidth';
import Typography from '@material-ui/core/Typography';
import {DESKTOP_CONTENT_MAX_WIDTH} from '../../../../constants/constants';
import classNames from 'classnames';
import SwipeableViews from 'react-swipeable-views';
import {autoPlay} from 'react-swipeable-views-utils';
import type {DefaultComponentProps} from '../../../../types/Types';
import Image from '../../helper/Image';

import sliderImage1LGJPG from '../../../../images/dest/home/slider_image_01-lg@1x.jpg';
import sliderImage1LGJPG2x from '../../../../images/dest/home/slider_image_01-lg@2x.jpg';
import sliderImage1LGWebP from '../../../../images/dest/home/slider_image_01-lg@1x.webp';
import sliderImage1LGWebP2x from '../../../../images/dest/home/slider_image_01-lg@2x.webp';
import sliderImage1MDJPG from '../../../../images/dest/home/slider_image_01-md@1x.jpg';
import sliderImage1MDJPG2x from '../../../../images/dest/home/slider_image_01-md@2x.jpg';
import sliderImage1MDWebP from '../../../../images/dest/home/slider_image_01-md@1x.webp';
import sliderImage1MDWebP2x from '../../../../images/dest/home/slider_image_01-md@2x.webp';
import sliderImage1SMJPG from '../../../../images/dest/home/slider_image_01-sm@1x.jpg';
import sliderImage1SMJPG2x from '../../../../images/dest/home/slider_image_01-sm@2x.jpg';
import sliderImage1SMWebP from '../../../../images/dest/home/slider_image_01-sm@1x.webp';
import sliderImage1SMWebP2x from '../../../../images/dest/home/slider_image_01-sm@2x.webp';
import sliderImage1XSJPG from '../../../../images/dest/home/slider_image_01-xs@1x.jpg';
import sliderImage1XSJPG2x from '../../../../images/dest/home/slider_image_01-xs@2x.jpg';
import sliderImage1XSWebP from '../../../../images/dest/home/slider_image_01-xs@1x.webp';
import sliderImage1XSWebP2x from '../../../../images/dest/home/slider_image_01-xs@2x.webp';

import sliderImage2LGJPG from '../../../../images/dest/home/slider_image_02-lg@1x.jpg';
import sliderImage2LGJPG2x from '../../../../images/dest/home/slider_image_02-lg@2x.jpg';
import sliderImage2LGWebP from '../../../../images/dest/home/slider_image_02-lg@1x.webp';
import sliderImage2LGWebP2x from '../../../../images/dest/home/slider_image_02-lg@2x.webp';
import sliderImage2MDJPG from '../../../../images/dest/home/slider_image_02-md@1x.jpg';
import sliderImage2MDJPG2x from '../../../../images/dest/home/slider_image_02-md@2x.jpg';
import sliderImage2MDWebP from '../../../../images/dest/home/slider_image_02-md@1x.webp';
import sliderImage2MDWebP2x from '../../../../images/dest/home/slider_image_02-md@2x.webp';
import sliderImage2SMJPG from '../../../../images/dest/home/slider_image_02-sm@1x.jpg';
import sliderImage2SMJPG2x from '../../../../images/dest/home/slider_image_02-sm@2x.jpg';
import sliderImage2SMWebP from '../../../../images/dest/home/slider_image_02-sm@1x.webp';
import sliderImage2SMWebP2x from '../../../../images/dest/home/slider_image_02-sm@2x.webp';
import sliderImage2XSJPG from '../../../../images/dest/home/slider_image_02-xs@1x.jpg';
import sliderImage2XSJPG2x from '../../../../images/dest/home/slider_image_02-xs@2x.jpg';
import sliderImage2XSWebP from '../../../../images/dest/home/slider_image_02-xs@1x.webp';
import sliderImage2XSWebP2x from '../../../../images/dest/home/slider_image_02-xs@2x.webp';

import sliderImage3LGJPG from '../../../../images/dest/home/slider_image_03-lg@1x.jpg';
import sliderImage3LGJPG2x from '../../../../images/dest/home/slider_image_03-lg@2x.jpg';
import sliderImage3LGWebP from '../../../../images/dest/home/slider_image_03-lg@1x.webp';
import sliderImage3LGWebP2x from '../../../../images/dest/home/slider_image_03-lg@2x.webp';
import sliderImage3MDJPG from '../../../../images/dest/home/slider_image_03-md@1x.jpg';
import sliderImage3MDJPG2x from '../../../../images/dest/home/slider_image_03-md@2x.jpg';
import sliderImage3MDWebP from '../../../../images/dest/home/slider_image_03-md@1x.webp';
import sliderImage3MDWebP2x from '../../../../images/dest/home/slider_image_03-md@2x.webp';
import sliderImage3SMJPG from '../../../../images/dest/home/slider_image_03-sm@1x.jpg';
import sliderImage3SMJPG2x from '../../../../images/dest/home/slider_image_03-sm@2x.jpg';
import sliderImage3SMWebP from '../../../../images/dest/home/slider_image_03-sm@1x.webp';
import sliderImage3SMWebP2x from '../../../../images/dest/home/slider_image_03-sm@2x.webp';
import sliderImage3XSJPG from '../../../../images/dest/home/slider_image_03-xs@1x.jpg';
import sliderImage3XSJPG2x from '../../../../images/dest/home/slider_image_03-xs@2x.jpg';
import sliderImage3XSWebP from '../../../../images/dest/home/slider_image_03-xs@1x.webp';
import sliderImage3XSWebP2x from '../../../../images/dest/home/slider_image_03-xs@2x.webp';

type HomeProps = DefaultComponentProps & {}
type HomeState = {
    width: number,
    height: number,
    activeSlider: number
}

const MOBILE_HERO_HEIGHT = 0.6;

const style: Function = (theme: Object): Object => ({
    root: {
        position: 'relative',
        display: 'flex',
        justifyContent: 'center',
        overflow: 'hidden'
    },
    slideSurround: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'relative',
        overflow: 'hidden'
    },
    image: {
        minHeight: '100%',
        minWidth: '100%',
        height: 'auto',
        width: 'auto',
        position: 'absolute',
        top: '-100%',
        bottom: '-100%',
        left: '-100%',
        right: '-100%',
        margin: 'auto',
        zIndex: 1
    },
    headlineSurround: {
        position: 'relative',
        zIndex: 2,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
    },
    headline: {
        color: theme.palette.primary.contrastText,
        fontSize: 80,
        fontWeight: 400,
        textShadow: '2px 2px #000000',
        [theme.breakpoints.down('sm')]: {
            fontSize: 25,
            lineHeight: '29px'
        }
    },
    button: {
        ...theme.button,
        marginTop: 20
    },
    sliderSurround: {
        maxWidth: DESKTOP_CONTENT_MAX_WIDTH,
        margin: '0 auto',
        position: 'absolute',
        zIndex: 2,
        bottom: 41,
        display: 'flex',
        [theme.breakpoints.down('sm')]: {
            bottom: 17,
            left: 30
        }
    },
    sliderItem: {
        display: 'flex',
        marginRight: 50,
        [theme.breakpoints.down('sm')]: {
            marginRight: 12
        }
    },
    sliderItemSurround: {},
    sliderItemNumber: {
        color: theme.palette.primary.contrastText,
        fontSize: 20,
        fontWeight: 500,
        marginRight: 9,
        [theme.breakpoints.down('lg')]: {
            fontSize: 16
        },
        [theme.breakpoints.down('sm')]: {
            fontWeight: 500,
            lineHeight: 1,
            marginRight: 0
        }
    },
    sliderItemDivider: {
        color: theme.palette.primary.contrastText,
        fontSize: 55,
        opacity: 0.5,
        fontWeight: 200,
        lineHeight: '65px',
        marginRight: 20,
        [theme.breakpoints.down('lg')]: {
            fontSize: 40,
            lineHeight: '52px'
        },
        [theme.breakpoints.down('sm')]: {
            display: 'none'
        }
    },
    sliderItemCaption: {
        color: theme.palette.primary.contrastText,
        fontSize: 20,
        fontWeight: 400,
        [theme.breakpoints.down('lg')]: {
            fontSize: 16
        },
        [theme.breakpoints.down('sm')]: {
            display: 'none'
        }
    },
    sliderItemText: {
        color: theme.palette.primary.contrastText,
        fontSize: 28,
        fontWeight: 400,
        [theme.breakpoints.down('lg')]: {
            fontSize: 20
        },
        [theme.breakpoints.down('sm')]: {
            display: 'none'
        }
    },
    sliderItemProgress: {
        transition: 'width 7s linear, opacity 0.2s linear',
        background: theme.palette.primary.main,
        width: 0,
        height: 4,
        opacity: 0,
        marginTop: 45,
        [theme.breakpoints.down('sm')]: {
            marginTop: 5
        }
    },
    sliderItemProgressActive: {
        width: '100%',
        opacity: 1
    },
    link: theme.link,
    stepSurround: {
        display: 'flex',
        justifyContent: 'center',
        marginTop: 20,
        position: 'absolute',
        left: 0,
        width: '100%',
        bottom: 20,
        zIndex: 10
    },
    step: {
        display: 'block',
        background: '#D8D8D8',
        width: 10,
        height: 10,
        borderRadius: 5,
        marginRight: 7,
        transition: 'all 0.25s cubic-bezier(0.55, 0, 0.55, 1)',
        cursor: 'pointer'
    },
    stepActive: {
        background: theme.palette.primary.main
    }
});

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

class Home extends React.Component<HomeProps, HomeState> {

    constructor(props: HomeProps) {
        super(props);

        this.state = {
            width: window.innerWidth,
            height: window.innerHeight * (isWidthDown('sm', this.props.width) ? MOBILE_HERO_HEIGHT : 0.7),
            activeSlide: 0
        };
        this.updateDimension();
        window.addEventListener('resize', this.updateDimension.bind(this));
        window.addEventListener('orientationchange', this.updateDimension.bind(this));
    }

    updateDimension() {
        this.setState({
            width: window.innerWidth,
            height: window.innerHeight * (isWidthDown('sm', this.props.width) ? MOBILE_HERO_HEIGHT : 0.7)
        });
    }

    componentDidMount() {
        this.updateDimension();
    }

    render() {
        return (
            <div className={this.props.classes.root} style={{
                width: this.state.width,
                height: this.state.height
            }}>
                <AutoPlaySwipeableViews index={this.state.activeSlide} interval={7000}
                                        style={{
                                            height: this.state.height,
                                            width: this.state.width,
                                            overflow: 'hidden'
                                        }} containerStyle={{
                    width: this.state.width
                }} onChangeIndex={(index: number) => this.setState({
                    activeSlide: index
                })}>
                    <div className={this.props.classes.slideSurround}
                         style={{
                             height: this.state.height,
                             width: this.state.width
                         }}>
                        <Image srcSet={{
                            webP: {
                                lg2x: sliderImage1LGWebP2x,
                                lg: sliderImage1LGWebP,
                                md2x: sliderImage1MDWebP2x,
                                md: sliderImage1MDWebP,
                                sm2x: sliderImage1SMWebP2x,
                                sm: sliderImage1SMWebP,
                                xs2x: sliderImage1XSWebP2x,
                                xs: sliderImage1XSWebP
                            },
                            fallback: {
                                lg2x: sliderImage1LGJPG2x,
                                lg: sliderImage1LGJPG,
                                md2x: sliderImage1MDJPG2x,
                                md: sliderImage1MDJPG,
                                sm2x: sliderImage1SMJPG2x,
                                sm: sliderImage1SMJPG,
                                xs2x: sliderImage1XSJPG2x,
                                xs: sliderImage1XSJPG
                            }
                        }} alt={''} className={this.props.classes.image}/>
                        <div className={this.props.classes.headlineSurround}>
                            <Typography variant={'h1'} className={this.props.classes.headline}>
                                {this.props.localize('home.hero.slider.text.1')}
                            </Typography>
                        </div>
                    </div>
                    <div className={this.props.classes.slideSurround}
                         style={{
                             height: this.state.height,
                             width: this.state.width
                         }}>
                        <Image forceLoad={true} srcSet={{
                            webP: {
                                lg2x: sliderImage2LGWebP2x,
                                lg: sliderImage2LGWebP,
                                md2x: sliderImage2MDWebP2x,
                                md: sliderImage2MDWebP,
                                sm2x: sliderImage2SMWebP2x,
                                sm: sliderImage2SMWebP,
                                xs2x: sliderImage2XSWebP2x,
                                xs: sliderImage2XSWebP
                            },
                            fallback: {
                                lg2x: sliderImage2LGJPG2x,
                                lg: sliderImage2LGJPG,
                                md2x: sliderImage2MDJPG2x,
                                md: sliderImage2MDJPG,
                                sm2x: sliderImage2SMJPG2x,
                                sm: sliderImage2SMJPG,
                                xs2x: sliderImage2XSJPG2x,
                                xs: sliderImage2XSJPG
                            }
                        }} alt={''} className={this.props.classes.image}/>
                        <div className={this.props.classes.headlineSurround}>
                            <Typography variant={'h1'} className={this.props.classes.headline}>
                                {this.props.localize('home.hero.slider.text.2')}
                            </Typography>
                        </div>
                    </div>
                    <div className={this.props.classes.slideSurround}
                         style={{
                             height: this.state.height,
                             width: this.state.width
                         }}>
                        <Image forceLoad={true} srcSet={{
                            webP: {
                                lg2x: sliderImage3LGWebP2x,
                                lg: sliderImage3LGWebP,
                                md2x: sliderImage3MDWebP2x,
                                md: sliderImage3MDWebP,
                                sm2x: sliderImage3SMWebP2x,
                                sm: sliderImage3SMWebP,
                                xs2x: sliderImage3XSWebP2x,
                                xs: sliderImage3XSWebP
                            },
                            fallback: {
                                lg2x: sliderImage3LGJPG2x,
                                lg: sliderImage3LGJPG,
                                md2x: sliderImage3MDJPG2x,
                                md: sliderImage3MDJPG,
                                sm2x: sliderImage3SMJPG2x,
                                sm: sliderImage3SMJPG,
                                xs2x: sliderImage3XSJPG2x,
                                xs: sliderImage3XSJPG
                            }
                        }} alt={''} className={this.props.classes.image}/>
                        <div className={this.props.classes.headlineSurround}>
                            <Typography variant={'h1'} className={this.props.classes.headline}>
                                {this.props.localize('home.hero.slider.text.3')}
                            </Typography>
                        </div>
                    </div>
                </AutoPlaySwipeableViews>
                <div className={this.props.classes.stepSurround}>
                    {[0, 1, 2].map((service: Object, index: number) =>
                        <span key={`home-hero-image-${index}`} onClick={() => this.setState({activeSlide: index})} className={classNames(this.props.classes.step, index === this.state.activeSlide ? this.props.classes.stepActive : '')}/>)}
                </div>
            </div>
        );
    }
}

export default withWidth()(withStyles(style)(withLocalization(Home)));
