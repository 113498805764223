// @flow
import React from 'react';
import {withStyles} from '@material-ui/core/styles';
import type {DefaultComponentProps} from '../../../../types/Types';
import Image from '../../helper/Image';
import openFireLGWebP2x from '../../../../images/dest/home/kaminfeuer_web-lg@2x.webp';
import openFireLGWebP from '../../../../images/dest/home/kaminfeuer_web-lg@1x.webp';
import openFireMDWebP2x from '../../../../images/dest/home/kaminfeuer_web-md@2x.webp';
import openFireMDWebP from '../../../../images/dest/home/kaminfeuer_web-md@1x.webp';
import openFireSMWebP2x from '../../../../images/dest/home/kaminfeuer_web-sm@2x.webp';
import openFireSMWebP from '../../../../images/dest/home/kaminfeuer_web-sm@1x.webp';
import openFireXSWebP2x from '../../../../images/dest/home/kaminfeuer_web-xs@2x.webp';
import openFireXSWebP from '../../../../images/dest/home/kaminfeuer_web-xs@1x.webp';
import openFireLGJPG2x from '../../../../images/dest/home/kaminfeuer_web-lg@2x.jpg';
import openFireLGJPG from '../../../../images/dest/home/kaminfeuer_web-lg@1x.jpg';
import openFireMDJPG2x from '../../../../images/dest/home/kaminfeuer_web-md@2x.jpg';
import openFireMDJPG from '../../../../images/dest/home/kaminfeuer_web-md@1x.jpg';
import openFireSMJPG2x from '../../../../images/dest/home/kaminfeuer_web-sm@2x.jpg';
import openFireSMJPG from '../../../../images/dest/home/kaminfeuer_web-sm@1x.jpg';
import openFireXSJPG2x from '../../../../images/dest/home/kaminfeuer_web-xs@2x.jpg';
import openFireXSJPG from '../../../../images/dest/home/kaminfeuer_web-xs@1x.jpg';

type DividerProps = DefaultComponentProps & {
    headline: string,
    date: string,
    content: string
};

type DividerState = {
    width: number
}

const style: Function = (theme: Object): Object => ({
    root: {
        position: 'relative',
        width: '100%',
        background: theme.palette.primary.dark,
        marginBottom: 200,
        [theme.breakpoints.down('sm')]: {
            marginBottom: 35
        }
    },
    image: {
        position: 'absolute',
        width: '93%',
        top: -65,
        left: 0,
        [theme.breakpoints.down('sm')]: {
            top: -12,
            width: '90%',
            left: '5%'
        }
    },
});

class Divider extends React.Component<DividerProps, DividerState> {

    constructor(props: DividerProps) {
        super(props);
        this.state = {
            width: window.innerWidth
        };
        window.addEventListener('resize', this.updateWidth.bind(this));
    }

    updateWidth() {
        this.setState({
            width: window.innerWidth
        });
    }

    getContainerDimension() {
        return {
            height: this.state.width * 0.93 * 0.613
        }
    }

    render() {
        return (
            <div className={this.props.classes.root} style={this.getContainerDimension()}>
                <Image srcSet={{
                    webP: {
                        lg2x: openFireLGWebP2x,
                        lg: openFireLGWebP,
                        md2x: openFireMDWebP2x,
                        md: openFireMDWebP,
                        sm2x: openFireSMWebP2x,
                        sm: openFireSMWebP,
                        xs2x: openFireXSWebP2x,
                        xs: openFireXSWebP
                    },
                    fallback: {
                        lg2x: openFireLGJPG2x,
                        lg: openFireLGJPG,
                        md2x: openFireMDJPG2x,
                        md: openFireMDJPG,
                        sm2x: openFireSMJPG2x,
                        sm: openFireSMJPG,
                        xs2x: openFireXSJPG2x,
                        xs: openFireXSJPG
                    }
                }} alt={''} className={this.props.classes.image} />
            </div>
        );
    }
}

export default withStyles(style)(Divider);