import React from 'react';
import {withLocalization} from 'react-localize';
import {withStyles} from '@material-ui/core/styles';
import Fab from '@material-ui/core/Fab';
import MenuIcon from '@material-ui/icons/Menu';
import AddIcon from '@material-ui/icons/Add';
import NavigateBefore from '@material-ui/icons/NavigateBefore';
import logo from '../../../images/logo/logo.svg';
import classNames from 'classnames';
import type {DefaultComponentProps} from '../../../types/Types';
import {withRouter} from 'react-router';

type MobileHeaderProps = DefaultComponentProps & {
    toggleMenu: Function,
    menuOpen: boolean
}

const style: Function = (theme: Object): Object => ({
    root: {
        position: 'fixed',
        bottom: 20,
        width: 56,
        height: 56,
        left: '50%',
        marginLeft: -28,
        color: theme.palette.primary.contrastText,
        zIndex: 20,
        transition: 'all 0.25s cubic-bezier(0.55, 0, 0.55, 1)'
    },
    back: {
        position: 'fixed',
        bottom: 20,
        left: '50%',
        transform: 'translateX(-50%)',
        zIndex: 20
    },
    logo: {
        position: 'fixed',
        top: 20,
        left: '50%',
        transform: 'translateX(-42%)',
        width: 105,
        zIndex: 20,
        transition: 'all 0.25s cubic-bezier(0.55, 0, 0.55, 1) 0.25s'
    },
    logoHidden: {
        opacity: 0
    },
    openedButton: {
        transform: 'rotate(45deg)',
        background: `${theme.palette.primary.contrastText} !important`,
        color: theme.palette.primary.main
    }
});

class MobileHeader extends React.Component<MobileHeaderProps> {

    render() {
        const shouldToggleBack = this.props.location.pathname.startsWith('/property/');
        return (
            <div>
                <img src={logo} alt={''}
                     className={classNames(this.props.classes.logo, this.props.menuOpen ? this.props.classes.logoHidden : '')}/>
                <Fab color={'primary'} variant={shouldToggleBack ? 'extended' : 'round'}
                     className={shouldToggleBack ? this.props.classes.back : classNames(this.props.classes.root, this.props.menuOpen ? this.props.classes.openedButton : '')}
                     onClick={() => {
                         if (shouldToggleBack) {
                             this.props.history.push('/properties');
                         } else {
                             this.props.toggleMenu();
                         }
                     }}>
                    {
                        shouldToggleBack ?
                            [
                                <NavigateBefore/>,
                                this.props.localize('back')
                            ]
                            :
                            (this.props.menuOpen ?
                                <AddIcon/>
                                :
                                <MenuIcon/>)

                    }
                </Fab>
            </div>
        );
    }
}

export default withRouter(withStyles(style)(withLocalization(MobileHeader)));
