import React from 'react';
import {withLocalization} from 'react-localize';
import classNames from 'classnames';
import Typography from '@material-ui/core/Typography';
import {withStyles} from '@material-ui/core/styles';
import {Link} from 'react-router-dom';
import type {DefaultComponentProps} from '../../../types/Types';

type MobileMenuProps = DefaultComponentProps & {
    open: boolean,
    toggleMenu: Function,
    location: Object
}

const style: Function = (theme: Object): Object => ({
    root: {
        position: 'fixed',
        top: '100%',
        bottom: '-25%',
        left: 0,
        right: 0,
        borderTopLeftRadius: 500,
        borderTopRightRadius: 500,
        background: theme.palette.primary.main,
        transition: 'top 500ms cubic-bezier(0.55, 0, 0.55, 1), bottom 500ms cubic-bezier(0.55, 0, 0.55, 1), border-radius 500ms cubic-bezier(0.55, 0, 0.55, 1) 0.05s',
        zIndex: 19,
        perspective: '1200px',
        opacity: '0'
    },
    menuOpened: {
        top: 0,
        bottom: 0,
        borderRadius: 0,
        opacity: '1'
    },
    anchorSurround: {
        position: 'absolute',
        opacity: 0.4,
        transform: 'rotateX(35deg) translateY(-50%)',
        transition: 'transform 0.5s 0.25s, opacity 0.5s 0.25s',
        height: 265,
        top: '50%',
        width: '100%'
    },
    anchorSurroundOpened: {
        opacity: 1,
        transform: 'rotateX(0deg) translateY(-50%)'
    },
    link: {
        ...theme.link,
        color: theme.palette.primary.contrastText
    },
    menuItem: {
        color: theme.palette.primary.contrastText,
        fontFamily: 'Playfair Display',
        fontSize: 40,
        lineHeight: 1,
        transition: 'margin 0.5s',
        width: '100%',
        textAlign: 'center'
    },
    menuItemOpened: {
        marginBottom: 35
    },
});

class MobileMenu extends React.Component<MobileMenuProps> {

    render() {
        return (
            <div className={classNames(this.props.classes.root, this.props.open ? this.props.classes.menuOpened : '')}>
                <div
                    className={classNames(this.props.classes.anchorSurround, this.props.open ? this.props.classes.anchorSurroundOpened : '')}>
                    <Typography variant={'h1'} classes={{
                        root: classNames(this.props.classes.menuItem, this.props.open ? this.props.classes.menuItemOpened : '')
                    }}>
                        <Link className={this.props.classes.link} to={'/'}
                              onClick={this.props.toggleMenu.bind(this)}>
                            {this.props.localize('menu.home')}.
                        </Link>
                    </Typography>
                    <Typography variant={'h1'} classes={{
                        root: classNames(this.props.classes.menuItem, this.props.open ? this.props.classes.menuItemOpened : '')
                    }}>
                        <Link className={this.props.classes.link} to={'/properties'}
                              onClick={this.props.toggleMenu.bind(this)}>
                            {this.props.localize('menu.properties')}.
                        </Link>
                    </Typography>
                    <Typography variant={'h1'} classes={{
                        root: classNames(this.props.classes.menuItem, this.props.open ? this.props.classes.menuItemOpened : '')
                    }}>
                        <Link className={this.props.classes.link} to={'/team'}
                              onClick={this.props.toggleMenu.bind(this)}>
                            {this.props.localize('menu.team')}.
                        </Link>
                    </Typography>
                    <Typography variant={'h1'} classes={{
                        root: classNames(this.props.classes.menuItem, this.props.open ? this.props.classes.menuItemOpened : '')
                    }}>
                        <Link className={this.props.classes.link} to={'/contact'}
                              onClick={this.props.toggleMenu.bind(this)}>
                            {this.props.localize('menu.contact')}.
                        </Link>
                    </Typography>
                </div>
            </div>
        );
    }
}

export default withStyles(style)(withLocalization(MobileMenu));