import React from 'react';
import {withLocalization} from 'react-localize';
import Typography from '@material-ui/core/Typography';
import {withStyles} from '@material-ui/core/styles';
import {Link} from 'react-router-dom';
import logo from '../../../images/logo/logo_inverted.svg';
import type {DefaultComponentProps} from '../../../types/Types';
import classNames from 'classnames';

type FooterProps = DefaultComponentProps & {}

const style: Function = (theme: Object): Object => ({
    root: {
        width: '100%',
        position: 'relative',
        background: theme.palette.primary.dark,
        paddingTop: 225,
        [theme.breakpoints.down('lg')]: {
            paddingTop: 100
        }
    },
    logoSurround: {
        display: 'flex',
        justifyContent: 'center',
        marginLeft: 27,
    },
    logo: {
        width: 200,
        height: 150
    },
    contentSurround: {
        display: 'flex',
        width: 1622,
        margin: '0 auto',
        justifyContent: 'space-between',
        marginBottom: 50,
        marginTop: 104,
        [theme.breakpoints.down('lg')]: {
            width: '95%'
        },
        [theme.breakpoints.down('sm')]: {
            flexWrap: 'wrap',
            marginTop: 47,
            marginBottom: 0
        }
    },
    caption: {
        textTransform: 'none',
        color: 'white',
        fontWeight: 500,
        fontSize: 20,
        letterSpacing: '0.5px',
        lineHeight: 1,
        marginBottom: 18,
        [theme.breakpoints.down('sm')]: {
            fontSize: 18,
            textAlign: 'center'
        }
    },
    text: {
        color: theme.palette.secondary.light,
        fontWeight: 300,
        fontSize: 18,
        lineHeight: 1,
        marginBottom: 14,
        [theme.breakpoints.down('sm')]: {
            fontSize: 15,
            marginBottom: 10,
            textAlign: 'center'
        }
    },
    emailSurround: {
        display: 'flex',
        [theme.breakpoints.down('sm')]: {
            justifyContent: 'center'
        }
    },
    email: {
        paddingBottom: 15,
        cursor: 'pointer',
        '&:hover': {
            borderBottom: `2px solid ${theme.palette.primary.light}`,
        },
        [theme.breakpoints.down('sm')]: {
            borderBottom: `2px solid ${theme.palette.primary.light}`
        }
    },
    links: {
        display: 'flex',
        padding: '30px 15px 20px',
        justifyContent: 'center',
        borderTop: '2px solid rgba(205,205,205,0.3)',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            margin: '0 auto',
            paddingTop: 20,
            boxSizing: 'border-box',
            justifyContent: 'center'
        }
    },
    linkSurround: {
        color: 'white',
        textTransform: 'uppercase',
        fontWeight: 500,
        fontSize: 16,
        letterSpacing: '0.4px',
        marginRight: 48,
        [theme.breakpoints.down('sm')]: {
            fontSize: 13,
            marginRight: 20
        }
    },
    link: {
        ...theme.link,
        paddingBottom: 10,
        '&:hover': {
            borderBottom: `2px solid ${theme.palette.primary.light}`,
        }
    },
    lastLinkSurround: {
        marginRight: 0
    },
    copyrightSurround: {
        textAlign: 'center',
        color: theme.palette.secondary.light,
        fontWeight: 300,
        letterSpacing: '0.5px',
        paddingBottom: 100,
        [theme.breakpoints.down('sm')]: {
            margin: '0 auto',
            width: '100%'
        }
    },
    column: {
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            marginBottom: 33
        }
    },
    socialSurround: {
        paddingBottom: 30,
        display: 'flex',
        justifyContent: 'center',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            margin: '0 auto',
            paddingBottom: 20
        }
    },
    socialLink: {
        color: 'white',
        marginRight: 40,
        textTransform: 'capitalize',
        [theme.breakpoints.down('sm')]: {
            fontSize: 13,
            marginRight: 20
        }
    },
    socialLinkLast: {
        marginRight: 0
    },
    socialLinkInverted: {
        color: theme.palette.primary.contrastText
    },
});

class Footer extends React.Component<FooterProps> {

    render() {
        return (
            <div className={this.props.classes.root}>
                <div className={this.props.classes.logoSurround}>
                    <img src={logo} alt={process.env.REACT_APP_TITLE} className={this.props.classes.logo}/>
                </div>
                <div className={this.props.classes.contentSurround}>
                    <div className={this.props.classes.column}>
                        <Typography variant={'caption'} classes={{
                            root: this.props.classes.caption
                        }}>
                            {this.props.localize('footer.legal.name')}
                        </Typography>
                        <Typography variant={'body1'} classes={{
                            root: this.props.classes.text
                        }}>
                            {this.props.localize('footer.legal.street')}
                        </Typography>
                        <Typography variant={'body1'} classes={{
                            root: this.props.classes.text
                        }}>
                            {this.props.localize('footer.legal.city')}
                        </Typography>
                    </div>
                    <div className={this.props.classes.column}>
                        <Typography variant={'body1'} classes={{
                            root: this.props.classes.text
                        }}>
                            {this.props.localize('footer.phone')}
                        </Typography>
                        <Typography variant={'body1'} classes={{
                            root: classNames(this.props.classes.text, this.props.classes.emailSurround)
                        }}>
                            <span>
                                {this.props.localize('footer.email')} &nbsp;
                            </span>
                            <span className={this.props.classes.email}>
                                {this.props.localize('footer.email.content')}
                            </span>
                        </Typography>
                    </div>
                    <div className={this.props.classes.socialSurround}>
                        <Typography variant={'caption'}
                                    classes={{root: classNames(this.props.classes.socialLink, this.props.menuOpen ? this.props.classes.socialLinkInverted : '')}}>
                            <a rel={'noreferrer'} href={''} target={'_blank'} className={this.props.classes.link}>
                                {this.props.localize('')}
                            </a>
                        </Typography>
                        <Typography variant={'caption'}
                                    classes={{root: classNames(this.props.classes.socialLink, this.props.menuOpen ? this.props.classes.socialLinkInverted : '')}}>
                            <a rel={'noreferrer'} href={''} target={'_blank'} className={this.props.classes.link}>
                                {this.props.localize('')}
                            </a>
                        </Typography>
                        <Typography variant={'caption'} classes={{
                            root: classNames(this.props.classes.socialLink, this.props.classes.socialLinkLast, this.props.menuOpen ? this.props.classes.socialLinkInverted : '')
                        }}>
                            <a rel={'noreferrer'} href={''} target={'_blank'} className={this.props.classes.link}>
                                {this.props.localize('')}
                            </a>
                        </Typography>
                    </div>
                </div>
                <Typography variant={'body1'} classes={{
                    root: this.props.classes.copyrightSurround
                }}>
                    <div className={this.props.classes.links}>
                        <Typography variant={'body1'} classes={{
                            root: this.props.classes.linkSurround
                        }}>
                            <Link to={'/legalNotice'} className={this.props.classes.link}>
                                {this.props.localize('legal.notice')}
                            </Link>
                        </Typography>

                        <Typography variant={'body1'} classes={{
                            root: classNames(this.props.classes.linkSurround, this.props.classes.lastLinkSurround)
                        }}>
                            <Link to={'/privacy'} className={this.props.classes.link}>
                                {this.props.localize('privacy')}
                            </Link>
                        </Typography>
                    </div>
                    Copyright &copy; {new Date().getFullYear()}. All Rights Reserved.
                </Typography>
            </div>
        );
    }
}

export default withStyles(style)(withLocalization(Footer));
