// @flow
import {all, call} from 'redux-saga/effects';
import type {AllEffect, GenericAllEffect} from 'redux-saga/effects';
import contactSaga from './ContactSaga';
import estateSaga from './EstateSaga';

/**
 * The root saga of the web app. It calls all other sagas which will be used in the web app.
 *
 * @return {Generator<AllEffect | GenericAllEffect<any> | *>}
 */

export default function*(): Generator<AllEffect, GenericAllEffect<any>, any> {
    yield all([call(contactSaga), call(estateSaga)])
}
