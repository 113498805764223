// @flow
import React, {Component, lazy, Suspense} from 'react';
import {BrowserRouter} from 'react-router-dom';
import {Route} from 'react-router';
import {MuiThemeProvider, withStyles} from '@material-ui/core/styles';
import withWidth, {isWidthUp} from '@material-ui/core/withWidth';
import '../../App.css';
import theme from './theme/Theme';
import ScrollToTop from './helper/ScrollToTop';
import Header from './header/Header';
import MobileHeader from './header/MobileHeader';
import HomeSite from '../container/HomeSite';
import {LocalizationProvider, withLocalization} from 'react-localize';
import dictionary from '../../language/dictionary';
import type {DefaultComponentProps} from '../../types/Types';
import Footer from './footer/Footer';
import Menu from './menu/Menu';
import MobileMenu from './menu/MobileMenu';
import logo from '../../images/logo/logo.svg';
import FetchStatus from '../../api/FetchStatus';
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';

const Team = lazy(() => import('./sites/Team'));
const LegalNotice = lazy(() => import('./sites/LegalNotice'));
const Privacy = lazy(() => import('./sites/Privacy'));
const TermsConditions = lazy(() => import('./sites/TermsConditions'));
const Contact = lazy(() => import('../container/ContactSite'));
const Properties = lazy(() => import('../container/PropertiesSite'));
const PropertyDetailSite = lazy(() => import('../container/PropertyDetailSite'));

type AppProps = DefaultComponentProps & {
    width: string,
    language: string,
    menuOpen: boolean,
    toggleMenu: Function,
    loadEstates: Function,
    contactFetchStatus: number,
    location: Object
};

type AppState = {
    snackBar: { message: string, open: boolean, success: boolean }
}

const style: Function = (theme: Object): Object => ({
    root: {},
    loader: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'absolute',
        top: 0,
        left: 0,
        bottom: 0,
        right: 0
    },
    logo: {
        width: '40%'
    },
    success: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText
    },
    error: {
        backgroundColor: 'white',
        color: 'red'
    },
    snackSurround: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'relative',
        zIndex: 30
    },
    snackBarMessage: {
        marginLeft: 20
    }
});

const deleteCookie = (name: string) => {
    document.cookie = `${name} =; Path=/; Domain=.immobilienhaus-hamburg.de; Expires=Thu, 01 Jan 1970 00:00:01 GMT;`;
}

class App extends Component<AppProps, AppState> {

    constructor(props: AppProps) {
        super(props);
        this.state = {
            snackBar: {
                open: false,
                message: '',
                success: true
            }
        }
    }

    componentDidMount() {
        // Delete existing cookies
        deleteCookie('_gat');
        deleteCookie('_gid');
        deleteCookie('_ga');
        this.props.loadEstates();
    }


    componentWillReceiveProps(nextProps: AppProps) {
        // TODO Localize this part
        if (this.props.contactFetchStatus === FetchStatus.ACTIVE && nextProps.contactFetchStatus === FetchStatus.SUCCESS) {
            this.setState({
                snackBar: {
                    open: true,
                    message: 'Ihre Nachricht wurde erfolgreich gesendet.',
                    success: true
                }
            });
        } else if (this.props.contactFetchStatus === FetchStatus.ACTIVE && nextProps.contactFetchStatus === FetchStatus.ERROR) {
            this.setState({
                snackBar: {
                    open: true,
                    message: 'Leider konnte Ihre Nachricht nicht gesendet werden. Bitte versuchen Sie es später erneut.',
                    success: false
                }
            });
        }
    }

    render() {
        return (
            <BrowserRouter>
                <Suspense fallback={<div className={this.props.classes.loader}>
                    <img alt={''} src={logo} className={this.props.classes.logo}/>
                </div>}>
                    <ScrollToTop>
                        <LocalizationProvider messages={dictionary[this.props.language]}>
                            <Route path='/' />
                            <MuiThemeProvider theme={theme}>
                                <div className={this.props.classes.root}>
                                    {isWidthUp('md', this.props.width) ?
                                        <Menu open={this.props.menuOpen} toggleMenu={this.props.toggleMenu}/>
                                        :
                                        <MobileMenu open={this.props.menuOpen} toggleMenu={this.props.toggleMenu}/>}
                                    {isWidthUp('md', this.props.width) ?
                                        <Header menuOpen={this.props.menuOpen} toggleMenu={this.props.toggleMenu}/>
                                        :
                                        <MobileHeader menuOpen={this.props.menuOpen}
                                                      toggleMenu={this.props.toggleMenu}/>}
                                    <Route exact path='/' component={HomeSite}/>
                                    <Route path='/team' component={Team}/>
                                    <Route path='/property/:id' component={PropertyDetailSite}/>
                                    <Route path='/properties/:section?' component={Properties}/>
                                    <Route path='/contact' component={Contact}/>
                                    <Route path='/legalNotice' component={LegalNotice}/>
                                    <Route path='/privacy' component={Privacy}/>
                                    <Route path='/termsConditions' component={TermsConditions}/>
                                    <Footer/>

                                    <Snackbar
                                        anchorOrigin={{
                                            vertical: isWidthUp('md', this.props.width) ? 'bottom' : 'top',
                                            horizontal: 'center'
                                        }}
                                        open={this.state.snackBar.open}
                                        autoHideDuration={5000}
                                        onClose={() => this.setState({snackBar: {...this.state.snackBar, ...{open: false}}})}
                                    >
                                        <SnackbarContent
                                            className={this.state.snackBar.success ? this.props.classes.success : this.props.classes.error}
                                            message={
                                                <div
                                                    className={this.props.classes.snackSurround}>{this.state.snackBar.success ?
                                                    <CheckCircleIcon/> : <ErrorIcon/>}
                                                    <span className={this.props.classes.snackBarMessage}>
                                                        {this.state.snackBar.message}
                                                    </span>
                                                </div>}
                                        />
                                    </Snackbar>
                                </div>
                            </MuiThemeProvider>
                        </LocalizationProvider>
                    </ScrollToTop>
                </Suspense>
            </BrowserRouter>
        );
    }
}

export default withWidth()(withStyles(style)(withLocalization(App)));
